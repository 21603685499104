import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import AddBemoPanel from "./DeviceTypePanel/AddBemoPanel";
import AddLyraPanel from "./DeviceTypePanel/AddLyraPanel";
import DeviceAvatar from "../../Avatar/DeviceAvatar";
import { largeFont } from "../../../assets/theme";
import { t } from "i18next";


type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
};

const AddDevice = ({ setPage }: DetailsProps) => {
    const [deviceType, setDeviceType] = useState('lyra');


    const renderDevicetypeDropdown = () => {
        return (
            <Select sx={{ height: '50px', fontSize: largeFont }} id="manufacturer-select" value={deviceType} onChange={(e) => { setDeviceType(e.target.value); }}>
                <MenuItem sx={{ fontSize: largeFont }} value={'lyra'}>Lyra</MenuItem>
                <MenuItem sx={{ fontSize: largeFont }} value={'BEMO'}>bemo</MenuItem>
            </Select>
        );
    };

    const renderDevicePanel = () => {
        if (deviceType === 'lyra') {
            return <AddLyraPanel setPage={setPage} />;
        }
        return <AddBemoPanel />;
    };


    return (
        <Stack direction="column" sx={{ height: 'calc(100% - 10px)', overflow: 'auto' }}>
            <Stack sx={{ display: 'flex', padding: '20px 15px 20px 20px', width: '100%', }} alignItems="center" direction="row">
                <div style={{ marginRight: '20px' }}>
                    <DeviceAvatar size="large" typ={deviceType} />
                </div>
                <Stack direction="column" sx={{ width: '100%' }}>
                    <Typography fontWeight="bold" fontSize={largeFont}>{t('device-form-type-label')}</Typography>
                    {renderDevicetypeDropdown()}
                </Stack>
            </Stack >
            {renderDevicePanel()}
        </Stack >
    );
};

export default AddDevice;
