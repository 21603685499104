import { useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { clearAccessToken } from '../../store/authSlice';
import { CHECK_INACTIVITY_INTERVALL, INACTIVITY_TIMEMOUT } from '../../config/constanst';
import { enqueueSnackbar } from 'notistack';
import { t } from 'i18next';

const InactivityLogoutMessage = () => {
    return (<div style={{ padding: '5px' }}>{t('automatic-logout-message')}</div>);
};

const InactivityLogoutProvider = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        let lastActivity = new Date();
        window.onmousemove = () => {
            lastActivity = new Date();
        };

        const checkInactivityHandler = window.setInterval(
            () => {
                const inactivityTime = new Date().getTime() - lastActivity.getTime();
                if (inactivityTime >= INACTIVITY_TIMEMOUT) {
                    enqueueSnackbar(InactivityLogoutMessage(), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, persist: true, key: 'inactivity-logout-error', preventDuplicate: true, variant: 'warning' });
                    dispatch(clearAccessToken());
                }
            }, CHECK_INACTIVITY_INTERVALL);

        return () => {
            window.clearInterval(checkInactivityHandler);
        };
    }, [dispatch]);


    return <></>;
};

export default InactivityLogoutProvider;
