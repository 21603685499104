
import { Stack } from "@mui/material";
import Avatar from "../../../Avatar/Avatar";
import { getFullNameAndCode, getFullNameOrCode } from "../../../ResultAssignment/fragments/PinnedPatientList";
import DetailsHeader from "../../../CloudMember/DetailsPanel/DetailsHeader";
import { t } from "i18next";
import { Patient } from "../../../../types/types";

type Props = {
    patient: Patient;
};

const TrainingsSettingsAvatar = ({ patient }: Props) => {
    return (
        <Stack direction="row" sx={{ width: '100%', marginBottom: '20px' }} spacing={(2)}>
            <Avatar size="large" name={getFullNameOrCode(patient)} />
            <DetailsHeader name={t('last-sessions-avatar-title')}
                memberType={getFullNameAndCode(patient)}
                isActive={true}
                isDeleted={false}
            />
        </Stack>
    );
};
export default TrainingsSettingsAvatar;
