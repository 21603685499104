// Need to use the React-specific entry point to allow generating React hooks
import { CloudMember, CloudMemberRequest, ConnectToken, UpdateCloudMemberRequest } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const clinicApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getClinicList: builder.query<CloudMember[], void>({
            query: () => ({
                url: 'clinic/all',
                method: 'GET',

            }),
            providesTags: ['Clinic'],
        }),
        getAllClinics: builder.mutation<CloudMember[], void>({
            query: () => ({
                url: 'clinic/all',
                method: 'GET',

            }),
        }),
        addClinic: builder.mutation<CloudMember, CloudMemberRequest>({
            query: (clinic) => ({
                url: 'clinic/signup',
                method: 'POST',
                body: clinic,
            }),
            invalidatesTags: ['Clinic', 'User']
        }),
        getClinic: builder.query<CloudMember, string>({
            query: (id) => ({
                url: `clinic/get?id=${id}`,
                method: 'GET',
            }),
            providesTags: ['Clinic'],
        }),

        fetchClinic: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `clinic/get?id=${id}`,
                method: 'GET',
            }),
        }),
        deleteClinic: builder.mutation({
            query: (id) => ({
                url: `clinic/remove?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Clinic']
        }),
        getClinicToken: builder.mutation<ConnectToken, void>({
            query: () => ({
                url: 'clinic/get_clinic_token',
                method: 'GET',
            }),
        }),
        updateClinic: builder.mutation<CloudMember, UpdateCloudMemberRequest>({
            query: (clinic) => ({
                url: 'clinic/update',
                method: 'POST',
                body: clinic,
            }),
            invalidatesTags: ['Clinic']
        }),
        switchClinicActive: builder.mutation<CloudMember, string>({
            query: (id) => ({
                url: `clinic/switch_active?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Clinic']
        }),
        undeleteClinic: builder.mutation({
            query: (id) => ({
                url: `clinic/undelete?id=${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Clinic']
        }),
    }),
    overrideExisting: true,
});

export const { useFetchClinicMutation, useGetClinicTokenMutation, useGetAllClinicsMutation, useUndeleteClinicMutation, useSwitchClinicActiveMutation, useUpdateClinicMutation, useGetClinicListQuery, useDeleteClinicMutation, useAddClinicMutation, useGetClinicQuery } = clinicApi;
