import { useCallback, useEffect, useState } from 'react';
import TwoColumns from '../PageTemplates/TwoColumns';
import { t } from 'i18next';
import ButtonBar from './fragments/ButtonBar';
import { DeviceMessage } from '../../types/types';
import { useAppDispatch } from '../../store';
import { resetFilter } from '../../store/filterState';
import { resetListState } from '../../store/listStateSlice';
import { useGetDeviceListQuery } from '../../api/deviceApi';
import DeviceList from './fragments/DeviceList';
import LogsList from './fragments/LogsList';

export type SortState = {
    field: string;
    order: string;
};

const DeviceLog = () => {
    const [deviceId, setDeviceId] = useState("");
    const { isLoading, data } = useGetDeviceListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [deviceScrollPos, setDeviceScrollPos] = useState(0);
    const [sortState, setSortState] = useState<SortState | undefined>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    const showDetails = useCallback(
        (id: string, scrollPos: number) => {
            if (id !== deviceId) {
                setSortState(() => undefined);
            }
            setDeviceScrollPos(scrollPos);
            setDeviceId(() => id);
        }, [deviceId, setSortState, setDeviceId, setDeviceScrollPos]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (deviceId && deviceId !== "") {
                const selectedDevice = data.find((entry: DeviceMessage) => entry.id === deviceId);
                if (!selectedDevice && data[0].id !== deviceId) {
                    showDetails(data[0].id, deviceScrollPos);
                }
            } else {
                showDetails(data[0].id, deviceScrollPos);
            }
        }
    }, [isLoading, data, deviceId, deviceScrollPos, dispatch, showDetails]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    return (
        <TwoColumns
            leftWidth='65%'
            rightWidth='35%'
            leftComponent={<>
                <ButtonBar
                    searchFieldPlaceholder={t('device-searchfield-placeholder')}
                    addEntryLabel={t('device-add-button')}
                    showAddButton={false}
                    addNewEntry={() => { }} />
                <DeviceList scrollPos={deviceScrollPos} selectedId={deviceId} showDetails={showDetails} data={data} />
            </>}
            rightComponent={<LogsList setSortState={setSortState} sortState={sortState} deviceId={deviceId} />}
        />
    );
};

export default DeviceLog;
