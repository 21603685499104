import { useRef } from "react";
import EditedIcon from "../../../icons/EditedIcon";
import { Box } from "@mui/material";

type Props = {
    setAnchorEl: (anchor: HTMLButtonElement | undefined) => void;
};
const EditedIconElement = ({ setAnchorEl }: Props) => {
    const ref = useRef();
    return (
        <Box ref={ref} onClick={(e) => { setAnchorEl(ref.current); }} >
            <EditedIcon style={{ marginLeft: '10px', fontSize: '16pt' }} />
        </Box>
    );
};

export default EditedIconElement;
