import { useEffect, useRef } from "react";
import { Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Patient } from "../../../types/types";
import Avatar from "../../Avatar/Avatar";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, tinyFont } from "../../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectListField, selectListFilter, selectListOrder, setListOrderState } from "../../../store/listStateSlice";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";
import { SelectedPatient } from "../ResultAssignment";
import PinIcon from "../../../icons/PinIcon";
import PinIconActive from "../../../icons/PinIconActive";
import { useUpdatePinnedPatientsMutation } from "../../../api/patientApi";
import { isEmpty } from "./SessionDetailsBox";

export const getFullNameOrCode = (patient: Patient) => {
    if (!patient) {
        return '??';
    }
    if (!isEmpty(patient.sureName) || !isEmpty(patient.givenName)) {
        return `${patient.givenName} ${patient.sureName}`.trim();
    }
    return patient.patientCode;
};

export const getFullNameAndCode = (patient: Patient) => {
    if (!patient) {
        return '??';
    }
    if (!isEmpty(patient.sureName) || !isEmpty(patient.givenName)) {
        return `${patient.givenName} ${patient.sureName} - ${patient.patientCode}`.trim();
    }
    return patient.patientCode;
};

const sortData = (sortField: string, sortOrder: string, data: Patient[]) => {
    return data.slice().sort((a, b) => {
        const order = sortOrder === "up" ? 1 : -1;
        switch (sortField) {
            case 'patientCode':
                if ((a.patientCode) > (b.patientCode)) {
                    return order;
                }
                if ((a.patientCode) < (b.patientCode)) {
                    return -order;
                }
                break;
            case 'name':
                if (getFullNameOrCode(a) > getFullNameOrCode(b)) {
                    return order;
                }
                if (getFullNameOrCode(a) < getFullNameOrCode(b)) {
                    return -order;
                }
                break;
            default:
        }

        return 0;
    });
};

type ListProps = {
    showDetails: (patient: SelectedPatient, scrollPos: number) => void;
    selectedPatient?: SelectedPatient;
    scrollPos: number;
    patients: Patient[];
};

const PinnedPatientList = ({ patients, scrollPos, selectedPatient, showDetails }: ListProps) => {
    const [updatePinned] = useUpdatePinnedPatientsMutation();
    const dispatch = useAppDispatch();
    const sortOrder = useAppSelector(selectListOrder);
    const sortField = useAppSelector(selectListField);
    const ref = useRef<HTMLTableElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const filter = useAppSelector(selectListFilter);

    useEffect(() => {
        if (!selectedPatient && patients && patients.length > 0) {
            const pinnedList = patients.slice().sort((a, b) => {
                if (a.pinned) {
                    return -1;
                }
                if (b.pinned) {
                    return 1;
                }
                return 0;
            });

            showDetails({ name: getFullNameOrCode(pinnedList[0]), id: pinnedList[0].id, patientId: pinnedList[0].id }, 0);
        }
    }, [selectedPatient, patients, showDetails]);


    useEffect(() => {
        if (ref.current?.parentElement) {
            ref.current.parentElement.scrollTo({ top: scrollPos });
        }
    }, [ref, scrollPos]);

    const clickInRow = ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, patient: Patient) => {
        if (selectedPatient?.id !== patient.id) {
            if (tableRef.current) {
                showDetails({
                    name: getFullNameOrCode(patient), id: patient.id, patientId: patient.id
                }, tableRef.current?.scrollTop);
            } else {
                showDetails({ name: getFullNameOrCode(patient), id: patient.id, patientId: patient.id }, 0);
            }
        }
    });

    const isInFilter = (member: Patient) => {
        if (member.active &&
            (getFullNameOrCode(member).toLowerCase().indexOf(filter.toLowerCase()) >= 0
                || (member.patientCode && member.patientCode.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            )) {
            return true;
        }
        return false;
    };

    const updatePinnedPatient = (member: Patient) => {
        const action = member.pinned ? 'remove' : 'add';
        const request = {
            action,
            patientId: member.id
        };

        if (tableRef.current) {
            tableRef.current.scrollTo({ top: 0 });
        }

        updatePinned(request).then(() => {
        });
    };

    const getFilteredList = (list?: Patient[]) => {
        if (list) {
            const filteredList = list.filter((entry) => isInFilter(entry));
            const pinnedList = filteredList.slice().sort((a, b) => {
                if (a.pinned) {
                    return -1;
                }
                if (b.pinned) {
                    return 1;
                }
                return 0;
            });

            const mappedList = pinnedList.map((member: Patient) => {
                return (
                    <StyledTableRow
                        selected={selectedPatient?.id === member.id}
                        key={member.id} sx={{ height: '80px', cursor: 'pointer' }} hover onClick={(event) => clickInRow(event, member)}
                    >
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>

                        <DataTableCell onClick={() => { updatePinnedPatient(member); }} >
                            {member.pinned ? <PinIconActive style={{ fontSize: '20px' }} /> : <PinIcon style={{ fontSize: '20px' }} />}
                        </DataTableCell>


                        <DataTableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}><Avatar name={getFullNameOrCode(member)} size="small" />
                                <Typography fontSize={largeFont} pl={'10px'}>{getFullNameOrCode(member)}</Typography>
                            </div>
                        </DataTableCell>
                        <DataTableCell sx={{ width: '220px' }}>
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {member.patientCode}
                            </Typography>
                        </DataTableCell>
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                    </StyledTableRow>
                );
            });
            return mappedList;
        };
    };

    const renderList = () => {
        let list = patients;
        if (patients && sortField) {
            switch (sortOrder) {
                case "up":
                    list = sortData(sortField, "up", patients);
                    break;
                case "down":
                    list = sortData(sortField, "down", patients);
                    break;
            }
        }
        const result = getFilteredList(list);
        return result;
    };

    const getOpacity = (field: string, order: string) => {
        if (field === sortField && sortOrder === order) {
            return 1;
        }
        return 0.3;
    };

    const toggleColumnSearch = (name: string) => {
        const newOrder = sortOrder === "up" ? "down" : "up";
        dispatch(setListOrderState({ order: newOrder, field: name }));
    };

    const getSortedColumnHeader = (label: string, column: string) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    return (
        <TableContainer ref={tableRef} sx={{ height: 'calc(100% - 180px)' }}>
            <Table ref={ref} stickyHeader>
                <TableHead>
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ minWidth: '30px', width: '30px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ width: '47%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-name-header'), "name")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: '47%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-patient-code-header'), "patientCode")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table >
        </TableContainer >
    );
};
export default PinnedPatientList;;
