import { useEffect, useRef } from "react";
import { Grid2 as Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Patient } from "../../../types/types";
import Avatar from "../../Avatar/Avatar";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, tinyFont } from "../../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectListField, selectListFilter, selectListOrder, setListOrderState } from "../../../store/listStateSlice";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { useGetPatientListQuery, useUpdatePinnedPatientsMutation } from "../../../api/patientApi";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";
import { SelectedPatient } from "../../ResultAssignment/ResultAssignment";
import { getFullNameOrCode } from "../../ResultAssignment/fragments/PinnedPatientList";
import { getActiveStatus } from "../../CloudMember/MemberListPanels/CloudMemberList";
import SettingsIcon from "../../../icons/SettingsIcon";
import TrainingsResultIcon from "../../../icons/TrainingsResultIcon";
import { selectActiveFilter, selectDeletedFilter, selectInactiveFilter } from "../../../store/filterState";
import { styled } from "@mui/styles";
import { dateNumbersString } from "../../ResultAssignment/fragments/SessionDetailsBox";
import PinIconActive from "../../../icons/PinIconActive";
import PinIcon from "../../../icons/PinIcon";

const ButtonBox = styled('div')({
    paddingTop: '5px',
    textAlign: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.lightgreen.main,
    width: '40px',
    height: '40px',
    borderRadius: '5px',
});

type SortFields = Patient & {
    name: string;
};

const getActive = (data: Patient) => {
    if (data.active) {
        return '1';
    }
    return '0';
};

const getPinned = (data: Patient) => {
    if (data.pinned) {
        return '1';
    }
    return '0';
};

const getLastTherapySessionStart = (data: Patient) => {
    const value = data['lastTherapySessionStart'] ?? '';
    return value;
};

const getActiveOrder = (order: number, a: Patient, b: Patient) => {
    if (getActive(a) > getActive(b)) {
        return order;
    }
    if (getActive(a) < getActive(b)) {
        return -order;
    }
    return 0;
};

const getPinnedOrder = (order: number, a: Patient, b: Patient) => {
    if (getPinned(a) > getPinned(b)) {
        return order;
    }
    if (getPinned(a) < getPinned(b)) {
        return -order;
    }
    return 0;
};

const sortData = (sortField: keyof SortFields, sortOrder: string, data: Patient[]) => {
    if (!data) {
        return [];
    }
    return data.slice().sort((a, b) => {
        const order = sortOrder === "up" ? 1 : -1;
        switch (sortField) {
            case 'active':
                return getActiveOrder(order, a, b);
            case 'pinned':
                return getPinnedOrder(order, a, b);
            case 'name':
                if (getFullNameOrCode(a) > getFullNameOrCode(b)) {
                    return order;
                }
                if (getFullNameOrCode(a) < getFullNameOrCode(b)) {
                    return -order;
                }
                break;
            case 'lastTherapySessionStart':
                if (getLastTherapySessionStart(a) > getLastTherapySessionStart(b)) {
                    return order;
                }
                if (getLastTherapySessionStart(a) < getLastTherapySessionStart(b)) {
                    return -order;
                }
                break;
            default:
                if (a[sortField] > b[sortField]) {
                    return order;
                }
                if (a[sortField] < b[sortField]) {
                    return -order;
                }
        }
        return 0;
    });
};

type ListProps = {
    selectPatient: (patient: SelectedPatient, scrollPos: number) => void;
    showDashboard: (id: string) => void;
    openResults: (id: string) => void;
    openSettings: (id: string) => void;
    selectedPatient?: SelectedPatient;
    scrollPos: number;
};

const PatientList = ({ scrollPos, openSettings, openResults, showDashboard, selectedPatient, selectPatient }: ListProps) => {
    const [updatePinned] = useUpdatePinnedPatientsMutation();
    const dispatch = useAppDispatch();
    const sortOrder = useAppSelector(selectListOrder);
    const sortField = useAppSelector(selectListField) as keyof Patient;
    const active = useAppSelector(selectActiveFilter);
    const inactive = useAppSelector(selectInactiveFilter);
    const deleted = useAppSelector(selectDeletedFilter);
    const ref = useRef<HTMLTableElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const filter = useAppSelector(selectListFilter);

    const { data, isLoading } = useGetPatientListQuery(undefined, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (ref.current?.parentElement) {
            ref.current.parentElement.scrollTo({ top: scrollPos });
        }
    }, [ref, scrollPos]);

    useEffect(() => {
        if (!isLoading && data) {
            if (selectedPatient?.id === '' && data.length > 0) {
                selectPatient({ id: data[0].id, name: data[0].sureName, patientId: data[0].id }, 0);
            }
        }
    }, [isLoading, data, selectedPatient, selectPatient]);

    if (isLoading || !data) {
        return <>loading...</>;
    }

    const clickInRow = ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, patient: Patient) => {
        if (selectedPatient?.id !== patient.id) {
            if (tableRef.current) {
                selectPatient({ name: patient.sureName, id: patient.id, patientId: patient.id }, tableRef.current?.scrollTop);
            } else {
                selectPatient({ name: patient.sureName, id: patient.id, patientId: patient.id }, 0);
            }
        }
    });

    const isInFilter = (member: Patient) => {
        if (getFullNameOrCode(member).toLowerCase().indexOf(filter.toLowerCase()) >= 0 || (member.patientCode && member.patientCode.toLowerCase().indexOf(filter.toLowerCase()) >= 0)) {
            return true;
        }
        return false;
    };

    const updatePinnedPatient = (member: Patient) => {
        const action = member.pinned ? 'remove' : 'add';
        const request = {
            action,
            patientId: member.id
        };

        if (tableRef.current) {
            tableRef.current.scrollTo({ top: 0 });
        }

        updatePinned(request).then(() => {
        });
    };

    const getFilteredList = (list: Patient[]) => {
        const filteredList = list.filter((entry) => isInFilter(entry))
            .filter((entry) => (!active && !inactive) || (active && entry.active) || (inactive && !entry.active))
            .filter((entry) => !deleted || (deleted && entry.deleted));
        return filteredList;
    };

    const renderPatientList = (list?: Patient[]) => {
        if (list) {
            const pinnedList = list.slice().sort((a, b) => {
                if (a.pinned) {
                    return -1;
                }
                if (b.pinned) {
                    return 1;
                }
                return 0;
            });

            const mappedList = pinnedList.map((member: Patient) => {
                return (
                    <StyledTableRow
                        key={member.id} sx={{ height: '80px', cursor: 'pointer' }} hover onClick={(event) => clickInRow(event, member)}
                    >
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>

                        <DataTableCell onClick={() => { updatePinnedPatient(member); }} >
                            {member.pinned ? <PinIconActive style={{ fontSize: '20px' }} /> : <PinIcon style={{ fontSize: '20px' }} />}
                        </DataTableCell>

                        <DataTableCell onClick={() => showDashboard(member.id)} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar name={getFullNameOrCode(member)} size="small" />
                                <Typography fontSize={largeFont} pl={'10px'}>{getFullNameOrCode(member)}</Typography>
                            </div>
                        </DataTableCell>

                        <DataTableCell onClick={() => showDashboard(member.id)} >
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {member.patientCode}
                            </Typography>
                        </DataTableCell>

                        <DataTableCell onClick={() => showDashboard(member.id)} >
                            {getActiveStatus(member)}
                        </DataTableCell>

                        <DataTableCell onClick={() => showDashboard(member.id)} >
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {dateNumbersString(member.lastTherapySessionStart)}
                            </Typography>
                        </DataTableCell>

                        <DataTableCell onClick={() => openSettings(member.id)} >
                            {member.lastTherapySessionStart && <ButtonBox>
                                <SettingsIcon />
                            </ButtonBox>}
                        </DataTableCell>

                        <DataTableCell onClick={() => openResults(member.id)}>
                            {member.lastTherapySessionStart && <ButtonBox style={{ marginLeft: '20px' }}>
                                <TrainingsResultIcon sx={{ fontSize: '25px' }} />
                            </ButtonBox>}
                        </DataTableCell>

                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                    </StyledTableRow >
                );
            });
            return mappedList;
        }
    };

    const getSortedList = () => {
        let list = data ?? [];
        if (data && sortField) {
            switch (sortOrder) {
                case "up":
                    list = sortData(sortField, "up", data);
                    break;
                case "down":
                    list = sortData(sortField, "down", data);
                    break;
            }
        }
        return list;
    };

    const renderList = () => {
        const sortedList = getSortedList();
        const filteredList = getFilteredList(sortedList);
        const result = renderPatientList(filteredList);
        return result;
    };

    const getOpacity = (field: string, order: string) => {
        if (field === sortField && sortOrder === order) {
            return 1;
        }
        return 0.3;
    };

    const toggleColumnSearch = (name: string) => {
        const newOrder = sortOrder === "up" ? "down" : "up";
        dispatch(setListOrderState({ order: newOrder, field: name }));
    };

    const getSortedColumnHeader = (label: string, column: string) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    return (
        <TableContainer ref={tableRef} sx={{ height: 'calc(100% - 100px)' }}>
            <Table ref={ref} stickyHeader>
                <TableHead>
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ maxWidth: '20px', minWidth: '20px', width: '20px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ minWidth: '30px', width: '30px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ width: '30%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-name-header'), "name")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: '25%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-patient-code-header'), "patientCode")}
                        </HeaderTableCell>

                        <HeaderTableCell sx={{ width: '25%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-status-header'), "active")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: '20%' }}>
                            {getSortedColumnHeader(t('cloud-member-list-last-session-header'), "lastTherapySessionStart")}
                        </HeaderTableCell>

                        <HeaderTableCell sx={{}}>
                        </HeaderTableCell>

                        <HeaderTableCell sx={{}}>
                        </HeaderTableCell>

                        <HeaderTableCell sx={{ maxWidth: '20px', minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table >
        </TableContainer >
    );
};
export default PatientList;
