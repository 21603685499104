import { memo, useEffect, useState } from 'react';
import UserDetails from './fragments/UserDetails';
import TwoColumns from '../PageTemplates/TwoColumns';
import EditUser from './fragments/EditUser';
import { Pages } from '../CloudMember/MemberListPanels/listPanelGlobals';
import { t } from 'i18next';
import { useAddUserMutation, useGetUserListQuery } from '../../api/userApi';
import AddUser from './fragments/AddUser';
import UserList from './fragments/UserList';
import ButtonBar from './fragments/ButtonBar';
import { User as UserData } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { resetFilter } from '../../store/filterState';
import { selectIsClinicUser, selectUserRole } from '../../store/authSlice';
import { resetListState } from '../../store/listStateSlice';

const MemoUserList = memo(UserList);

const User = () => {
    const [userId, setUserId] = useState("");
    const [page, setPage] = useState(Pages.DETAILS);
    const [addUser] = useAddUserMutation();
    const { isLoading, data } = useGetUserListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [scrollPos, setScrollPos] = useState(0);
    const role = useAppSelector(selectUserRole);
    const isClinicUser = useAppSelector(selectIsClinicUser);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (userId && userId !== "") {
                const selectedMember = data.find((entry: UserData) => entry.id === userId);
                if (!selectedMember) {
                    showDetails(data[0].id, scrollPos);
                }
            } else {
                showDetails(data[0].id, scrollPos);
            }
        }
    }, [isLoading, data, userId, scrollPos, dispatch]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setUserId(() => id);
        setPage(Pages.DETAILS);
    };

    const addEntry = () => {
        setPage(Pages.ADD);
    };

    const getRightComponent = () => {
        switch (page) {
            case Pages.ADD:
                return (<AddUser role={role} addMember={addUser} setPage={setPage} />);
            case Pages.EDIT:
                return (<EditUser setPage={setPage} userId={userId} />);
            case Pages.DETAILS:
                return (<>{userId && <UserDetails setPage={setPage} userId={userId} />}</>);
            default:
                if (userId) {
                    return (<UserDetails setPage={setPage} userId={userId} />);
                }
                return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
        }

    };

    return (
        <TwoColumns leftComponent={
            <>
                <ButtonBar
                    searchFieldPlaceholder={t('user-searchfield-placeholder')}
                    addEntryLabel={t('user-add-button')}
                    showAddButton={!isClinicUser}
                    addNewEntry={addEntry} />
                <MemoUserList scrollPos={scrollPos} selectedId={userId} showDetails={showDetails} data={data} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default User;
