import { SetStateAction } from "react";
import { useDeleteManufacturerMutation, useGetManufacturerQuery, useSwitchManufacturerActiveMutation, useUndeleteManufacturerMutation } from "../../../api/manufacturerApi";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { useAppSelector } from "../../../store";
import { selectIsManufacturerAdmin, selectIsSystemaAdmin } from "../../../store/authSlice";
import CloudMemberDetails from "../../CloudMember/DetailsPanel/CloudMemberDetails";
import { t } from "i18next";
import { Trans } from "react-i18next";

type DetailsProps = {
    manufacturerId: string;
    setPage: (value: SetStateAction<Pages>) => void;
};

const ManufacturerDetails = ({ setPage, manufacturerId }: DetailsProps) => {
    const { data, isLoading } = useGetManufacturerQuery(manufacturerId, { refetchOnMountOrArgChange: true });
    const [switchActive] = useSwitchManufacturerActiveMutation();
    const [undelete] = useUndeleteManufacturerMutation();
    const [deleteManufacturer] = useDeleteManufacturerMutation();
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const deleteEntry = () => {
        if (data?.deleted) {
            undelete(manufacturerId);
            return;
        }
        deleteManufacturer(manufacturerId);
    };

    return (
        <CloudMemberDetails
            memberType={t('member-type-manufacturer')}
            deleteEntryMessage={<Trans i18nKey={'delete-manufacturer-message'} values={{ name: data?.name }} />}
            undeleteEntryMessage={<Trans i18nKey={'undelete-manufacturer-message'} values={{ name: data?.name }} />}
            data={data}
            switchActive={isSystemAdmin ? () => switchActive(manufacturerId) : undefined}
            editEntry={isSystemAdmin || isManufacturerAdmin ? () => setPage(Pages.EDIT) : undefined}
            deleteEntry={isSystemAdmin ? deleteEntry : undefined}
        />
    );
};

export default ManufacturerDetails;
