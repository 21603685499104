import { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { clearAccessToken, selectAccessToken, selectAccessTokenExpiresIn, selectRefreshToken, updateAccessToken, updateAccessTokenExpiresIn } from '../../store/authSlice';
import { isExpired } from "react-jwt";
import { CHECK_ACCESS_TOKEN_INTERVALL, MIN_ACCESS_TOKEN_TIME_LEFT } from '../../config/constanst';
import { RefreshTokenResponse } from '../../types/types';
import { useRefreshAccessTokenMutation, userApi } from '../../api/userApi';
import LoginPage from '../LoginPage/LoginPage';
import { retailerApi } from '../../api/retailerApi';
import { manufacturerApi } from '../../api/manufacturerApi';

const AuthenticationProvider = ({ children }: { children: ReactNode; }) => {
    const accessToken = useAppSelector(selectAccessToken);
    const refreshToken = useAppSelector(selectRefreshToken);
    const dispatch = useAppDispatch();
    const [refreshAccessToken] = useRefreshAccessTokenMutation();
    const tokenExpiresIn = useAppSelector(selectAccessTokenExpiresIn);

    useEffect(() => {
        const updateAccessTokenIfNeeded = (token: string | undefined) => {
            if (token) {
                if (refreshToken) {
                    if (tokenExpiresIn <= MIN_ACCESS_TOKEN_TIME_LEFT || isExpired(token)) {
                        console.log('### refresh token expired - update token');
                        refreshAccessToken({ refreshToken: refreshToken }).unwrap().then((payload: RefreshTokenResponse) => {
                            dispatch(updateAccessToken(payload));
                        }).catch((error: any) => {
                            console.log('### refresh token expired - clear access token');
                            dispatch(clearAccessToken());
                        });
                    }
                } else {
                    console.log('### refresh token not found - clear access token');
                    dispatch(clearAccessToken());
                }
            };
            dispatch(updateAccessTokenExpiresIn());
        };

        const expiredTokenUpdateHandler = window.setInterval(() => { updateAccessTokenIfNeeded(accessToken); }, CHECK_ACCESS_TOKEN_INTERVALL);
        return () => {
            window.clearInterval(expiredTokenUpdateHandler);
        };
    }, [dispatch, refreshAccessToken, refreshToken, tokenExpiresIn, accessToken]);

    // clear redux stores when not logged in
    if (!accessToken) {
        dispatch(userApi.util.resetApiState());
        dispatch(retailerApi.util.resetApiState());
        dispatch(manufacturerApi.util.resetApiState());
        return <LoginPage />;
    }
    return <>{children}</>;
};

export default AuthenticationProvider;
