import { SetStateAction, useEffect, useRef, useState } from "react";
import { Pages } from "../../../CloudMember/MemberListPanels/listPanelGlobals";
import { Box, Checkbox, Divider, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import StyledMultilineField from "../../../StyledTextField/StyledMultilineField";
import { t } from "i18next";
import StyledTextField from "../../../StyledTextField/StyledTextField";
import { useAppSelector } from "../../../../store";
import { useAddLyraDeviceMutation } from "../../../../api/deviceApi";
import { selectUserManufacturerId } from "../../../../store/authSlice";
import { CloudMember } from "../../../../types/types";
import { useGetAllRetailerMutation } from "../../../../api/retailerApi";
import { enqueueSnackbar } from "notistack";
import TheraButton from "../../../TheraButtons/TheraButton";
import { largeFont } from "../../../../assets/theme";
import { isEmpty } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import { DatePicker } from "rsuite";
import { v4 as uuid } from "uuid";

const StyledLabel = styled(Typography)({
    fontSize: largeFont,
    fontWeight: 'bold',
});

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type PanelProps = {
    setPage: (value: SetStateAction<Pages>) => void;
};

const AddLyraPanel = ({ setPage }: PanelProps) => {
    const token = useRef<HTMLInputElement>();
    const name = useRef<HTMLInputElement>();
    const serial = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();
    const manufacturerId = useAppSelector(selectUserManufacturerId);
    const [addDevice] = useAddLyraDeviceMutation();
    const [licenceDate, setLicenceDate] = useState("");
    const [debugMode, setDebugMode] = useState<boolean>(false);

    const [retailerId, setRetailerId] = useState('');
    const [retailerList, setRetailerList] = useState<CloudMember[]>();
    const [getRetailerList] = useGetAllRetailerMutation();

    useEffect(() => {
        getRetailerList().then((result) => {
            if ('data' in result) {
                setRetailerList(result.data);
            }
        });

    }, [getRetailerList]);

    const renderRetailerDropdown = () => {
        return (<Select sx={{ height: '50px', fontSize: largeFont }} id="retailer-select" value={retailerId} onChange={(e) => { setRetailerId(e.target.value); }}>
            {retailerList?.map((member: CloudMember) =>
                <MenuItem sx={{ fontSize: largeFont }} key={member.id} value={member.id}>{member.name}</MenuItem>
            )}
        </Select >);
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        if (name.current && isEmpty(name.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-sure-name-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }

        const params = {
            name: name.current?.value ?? '',
            therapyMethod: ['Gehtraining'],
            comment: comment.current?.value ?? '',
            serial: serial.current?.value ?? '',
            manufacturerId: manufacturerId ?? '',
            retailerId: retailerId ?? '',
            token: token.current?.value ?? '',
            licenceDate,
            debugMode
        };
        console.log('### %s', JSON.stringify(params, null, "\t"));
        addDevice(params);
        setPage(Pages.DETAILS);

    };

    return (
        <form onSubmit={handleSubmit} style={{ paddingLeft: '20px', height: '100%' }}>
            <Stack direction="column" sx={{ paddingRight: '15px', height: '100%' }} >
                <Stack sx={{ display: 'flex', width: '100%' }} direction="column">
                    <StyledLabel>{t('device-form-therapy-method-label')}</StyledLabel>
                    <Typography sx={{ fontSize: largeFont }}>Gehtraining</Typography>
                </Stack>

                <Divider sx={{ height: '20px' }} />

                < Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                    <StyledMultilineField
                        variant="outlined"
                        color="primary"
                        multiline
                        rows={3}
                        inputRef={comment}
                        slotProps={{
                            htmlInput: {
                                "data-testid": "comment"
                            }
                        }}
                    />
                </Stack>

                <Divider sx={{ height: '20px' }} />

                <Stack sx={{ display: 'flex', paddingTop: '10px', width: '100%', }} direction="column">
                    <StyledLabel pl={'0px'}>{t('device-form-name-label')}*</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type="text"
                        inputRef={name}
                        slotProps={{
                            htmlInput: {
                                "data-testid": "name"
                            }
                        }}

                        defaultValue="THERA-Trainer lyra"
                    />
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                    <StyledLabel pl={'0px'}>{t('device-form-serial-label')}*</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type="text"
                        inputRef={serial}
                        slotProps={{
                            htmlInput: {
                                "data-testid": "serial"
                            }
                        }}
                    />
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                    <StyledLabel>{t('device-form-retailer-label')}*</StyledLabel>
                    {renderRetailerDropdown()}
                </Stack>

                <Stack sx={{ display: 'flex', width: '100%', paddingTop: '20px' }} direction="column">
                    <StyledLabel>{t('device-form-token-label')}*</StyledLabel>
                    <StyledTextField
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        defaultValue={uuid()}
                        type="text"
                        inputRef={token}
                        slotProps={{
                            htmlInput: {
                                "data-testid": "token"
                            }
                        }}
                    />
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel pl={'0px'}>{t('device-form-debug-label')}</StyledLabel>
                    <Box><StyledLabel><Checkbox onChange={(event, checked) => setDebugMode(checked)} checked={debugMode} />ein</StyledLabel></Box>
                </Stack>

                <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                    <StyledLabel pl={'0px'}>{t('device-form-licence-label')}</StyledLabel>
                    <DatePicker
                        placement="topEnd"
                        onChange={(date) => setLicenceDate(date?.toISOString() ?? '')}
                        size="lg"
                        editable={false}
                        format="dd.MM.yyyy"
                        placeholder={t('device-form-pick-date-label')}
                        defaultValue={licenceDate ? new Date(Date.parse(licenceDate)) : undefined}

                    />

                </Stack>

                <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px', paddingBottom: '20px' }} >
                    <Stack spacing={'20px'} direction="row">
                        <TheraButton sx={{ width: '50%' }} onClick={() => setPage(Pages.DETAILS)} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                        <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                    </Stack>
                </Stack>
            </Stack>
        </form >
    );
};

export default AddLyraPanel;
