import { styled } from "@mui/material";
import { useAppSelector } from "../../../store";
import { selectActiveFilter, selectDeletedFilter, selectInactiveFilter } from "../../../store/filterState";
import { tinyFont } from "../../../assets/theme";


export const NumberElement = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: tinyFont,
    height: '22px',
    width: '22px',
    marginRight: '10px',
    color: theme.palette.background.default,
    backgroundColor: theme.palette.darkgreen.main,
    borderRadius: '11px',

}));

const FilterCount = () => {
    const active = useAppSelector(selectActiveFilter);
    const inactive = useAppSelector(selectInactiveFilter);
    const deleted = useAppSelector(selectDeletedFilter);
    let count = active ? 1 : 0;
    count += inactive ? 1 : 0;
    count += deleted ? 1 : 0;
    return <>{count > 0 ? < NumberElement > {count.toString()}</NumberElement > : undefined}</>;
};

export default FilterCount;
