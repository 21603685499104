import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { decodeToken } from 'react-jwt';
import { signIn } from '../api/authApi';

export enum UserRole {
    SystemAdmin = 'ROLE_SYSTEM_ADMIN',
    ManufacturerAdmin = 'ROLE_MANUFACTURER_ADMIN',
    RetailerAdmin = 'ROLE_RETAILER_ADMIN',
    ClinicAdmin = 'ROLE_CLINIC_ADMIN',
    ClinicUser = 'ROLE_CLINIC_USER',
    Unknown = ''
}

export type AuthState = {
    id: string,
    givenName: string,
    sureName: string,
    manufacturerId?: string,
    retailerId?: string,
    clinicId?: string,
    username: string,
    role: UserRole,
    tokenType?: string,
    accessToken?: string,
    refreshToken?: string;
    accesTokenExpiresIn: number,
};

const initialState: AuthState = {
    id: '',
    givenName: '',
    sureName: '',
    username: '',
    role: UserRole.Unknown,
    accesTokenExpiresIn: 0,
};

export function tokenExpiresIn(token?: string): number {
    if (!token) {
        return 0;
    }

    const decodedToken: any = decodeToken(token);
    let result: number = 0;

    if (decodedToken?.exp) {
        const expirationDate: Date = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp); // sets the expiration seconds
        // compare the expiration time and the current time
        result = expirationDate.valueOf() - new Date().valueOf();
    }
    return result;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAccessTokenExpiresIn: (state) => {
            state.accesTokenExpiresIn = tokenExpiresIn(state.accessToken);
        },
        updateAccessToken: (state, { payload }) => {
            state.accessToken = payload.accessToken;
            state.accesTokenExpiresIn = tokenExpiresIn(payload.accessToken);
        },
        clearAccessToken: (state) => {
            state.accessToken = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.fulfilled, (state, action) => {
            if (!action.payload.error) {
                state.id = action.payload.id;
                state.givenName = action.payload.givenName;
                state.sureName = action.payload.sureName;
                state.manufacturerId = action.payload.manufacturerId;
                state.retailerId = action.payload.retailerId;
                state.clinicId = action.payload.clinicId;
                state.tokenType = action.payload.tokenType;
                state.accessToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.username = action.payload.username;
                state.role = action.payload.role;
                state.accesTokenExpiresIn = tokenExpiresIn(action.payload.accessToken);
            }
        });
    },
});

export const { updateAccessTokenExpiresIn, updateAccessToken, clearAccessToken } = authSlice.actions;

export const selectTokenType = (state: RootState) => state.auth.tokenType;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAccessTokenExpiresIn = (state: RootState) => state.auth.accesTokenExpiresIn;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectId = (state: RootState) => state.auth.id;
export const selectEmail = (state: RootState) => state.auth.username;
export const selectUserManufacturerId = (state: RootState) => state.auth.manufacturerId;
export const selectUserRetailerId = (state: RootState) => state.auth.retailerId;
export const selectUserClinicId = (state: RootState) => state.auth.clinicId;
export const selectSureName = (state: RootState) => state.auth.sureName;
export const selectGivenName = (state: RootState) => state.auth.givenName;
export const selectAuthState = (state: RootState) => state.auth;
export const selectUserRole = (state: RootState) => state.auth.role;
export const selectIsAuthorized = (state: RootState) => tokenExpiresIn(state.auth.accessToken) > 0;
export const selectIsSystemaAdmin = (state: RootState) => state.auth.role === UserRole.SystemAdmin;
export const selectIsManufacturerAdmin = (state: RootState) => state.auth.role === UserRole.ManufacturerAdmin;
export const selectIsRetailerAdmin = (state: RootState) => state.auth.role === UserRole.RetailerAdmin;
export const selectIsClinicAdmin = (state: RootState) => state.auth.role === UserRole.ClinicAdmin;
export const selectIsClinicUser = (state: RootState) => state.auth.role === UserRole.ClinicUser;


