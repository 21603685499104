import { styled } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { largeFont } from "../../assets/theme";




const AlignedBubble = styled('div', {
    shouldForwardProp: (prop) => prop !== 'position',
})<{
    position: string;
}>(({ theme, position }) => ({
    display: 'block',
    position: 'absolute',
    background: 'white',
    color: 'black',
    fontSize: largeFont,
    boxShadow: '2px 2px 2px 0px #A3A3A3',
    border: 'solid 1px #a3a3a3',
    textAlign: 'center',
    borderRadius: '10px',
    zIndex: 999,
    "&:after": {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        zIndex: 1,
        borderStyle: 'solid',
        borderColor: 'white transparent',
        borderWidth: '20px 12px 0',
        bottom: '-20px',
        right: position === 'right' ? '30px' : undefined,
        left: position === 'left' ? '30px' : undefined,
        marginKeft: '-20px',
    }
}));

type Props = {
    setInfoAnchor: (infoAnchor: HTMLDivElement | undefined) => void;
    top: number;
    left: number;
    child: ReactNode;
    ignoreFirstClick?: boolean;
    position?: string;
};

const SpeechBubble = ({ position = 'left', ignoreFirstClick = true, child, setInfoAnchor, top, left }: Props) => {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        const clickHandler = () => {
            console.log('### click');
            if (initialized || ignoreFirstClick) {
                setInfoAnchor(undefined);
                window.removeEventListener('click', clickHandler);
            } else {
                setInitialized(true);
            }
        };
        window.addEventListener('click', clickHandler);
    }, [ignoreFirstClick, setInfoAnchor, setInitialized, initialized]);

    return (
        <AlignedBubble position={position} onClick={() => setInfoAnchor(undefined)} style={{ top: top, left: left }}>
            {child}
        </AlignedBubble>
    );
};

export default SpeechBubble;
