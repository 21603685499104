import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import StyledTextField from "../../../StyledTextField/StyledTextField";
import { useEffect, useRef } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import TheraButton from "../../../TheraButtons/TheraButton";
import theme, { largeFont } from "../../../../assets/theme";
import useGetMemberNames from "../../../../hooks/useGetMemberNames";

const DialogBox = styled(Box)({
    width: '600px',
    display: 'flex',
    zIndex: 99,
    padding: '10px',
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont
});

type DialogProps = {
    okCallback: (token?: string) => void;
    acceptText?: string;
    handleOk: (token?: string) => void;
};

const AcceptInvitationDialog = ({ handleOk, acceptText, okCallback }: DialogProps) => {
    const fieldRef = useRef<HTMLInputElement>();
    const { acceptMemberName } = useGetMemberNames();

    useEffect(() => {
        fieldRef.current?.focus();
    }, [fieldRef]);

    return (
        <DialogBox>
            <Stack direction="column" spacing={0} alignItems="start" sx={{ padding: '20px', width: '100%', height: '100%' }}>
                <Stack><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }} >
                    <Trans i18nKey={'accept-member-dialog-title'} values={{ name: acceptMemberName }} /></Typography>
                </Stack>
                <Stack sx={{ width: '100%' }}>
                    <Divider sx={{ paddingTop: '20px', width: '100%' }} />
                </Stack>
                <Stack pt="20px" pb="10px"><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }}>{t('accept-member-dialog-filed-label')}</Typography></Stack>
                <StyledTextField inputRef={fieldRef} sx={{ width: '100%' }}
                />
                <Stack pt="20px" pb="20px">
                    <Trans>{acceptText}</Trans>
                </Stack>
                <Stack sx={{ width: '100%', height: '100%' }} alignItems="end" pt="60px" spacing={'20px'} direction="row">
                    <TheraButton fullWidth onClick={() => okCallback()} size="medium" color="lightgreen" variant="contained">{t('cancel-button-label')}</TheraButton>
                    <TheraButton fullWidth onClick={() => handleOk(fieldRef.current?.value)} size="medium" color="success" variant="contained">{t('ok-button-label')}</TheraButton>
                </Stack>
            </Stack >
        </DialogBox >);
};

export default AcceptInvitationDialog;
