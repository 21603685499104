import { useEffect, useState } from "react";
import { MappedTherapySessionData } from "../types/types";
import { useGetAssignedTherapieSessionsQuery } from "../api/therapySessionsApi";
import useFetchDevices from "./useFetchDevices";
import { getDeviceKey } from "../components/ResultAssignment/fragments/SessionDetailsBox";

export const getUniqueDeviceKeys = (assignedSessions: MappedTherapySessionData[]) => {
    const sessions = assignedSessions.map((session) => getDeviceKey(session));
    const unique = [...new Set(sessions)];
    return unique;
};

export const getSessionsForType = (assignedSessions: MappedTherapySessionData[], deviceKey: string) => {
    if (!deviceKey) {
        return [];
    }
    return assignedSessions.filter(s => getDeviceKey(s) === deviceKey);
};

const useFetchAssignedSessions = (patientId: string) => {
    const { isDevicesLoading, devices, devicesError } = useFetchDevices();
    const { isLoading, data, error } = useGetAssignedTherapieSessionsQuery(patientId, { refetchOnMountOrArgChange: 60 });
    const [assignedSessions, setAssignedSessions] = useState<MappedTherapySessionData[]>([]);
    const [isAssignedSessionsLoading, setIsAssignedSessionsLoading] = useState(false);
    const [assignedSessionsError, setAssignedSessionsError] = useState<string | null>(null);

    useEffect(() => {
        setIsAssignedSessionsLoading(isLoading);
        if (error) {
            setAssignedSessionsError(JSON.stringify(error));
            setIsAssignedSessionsLoading(false);
        } else if (devicesError) {
            setAssignedSessionsError(devicesError.toString());
            setIsAssignedSessionsLoading(false);
        } else if (!isLoading && !isDevicesLoading && devices && data) {
            setAssignedSessions(data);
            setAssignedSessionsError(null);
            setIsAssignedSessionsLoading(false);
        }
    }, [isLoading, data, error, isDevicesLoading, devices, devicesError]);

    return { isAssignedSessionsLoading, assignedSessions, assignedSessionsError };

};

export default useFetchAssignedSessions;
