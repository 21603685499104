import { Box, Divider, InputAdornment, Stack, Typography, styled } from "@mui/material";
import StyledTextField from "../../../StyledTextField/StyledTextField";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { selectIsClinicAdmin, selectIsRetailerAdmin } from "../../../../store/authSlice";
import CopyIcon from "../../../../icons/CopyIcon";
import TheraButton from "../../../TheraButtons/TheraButton";
import theme, { largeFont } from "../../../../assets/theme";

const DialogBox = styled(Box)({
    width: '600px',
    display: 'flex',
    zIndex: 99,
    padding: '10px',
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont
});

type DialogProps = {
    okCallback: () => void;
    token?: string;
};
const InviteMemberDialog = ({ token, okCallback }: DialogProps) => {
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);

    const copyToClipboard = () => {
        if (token) {
            navigator.clipboard.writeText(token);
            enqueueSnackbar(t('copy-invite-token-success'), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, preventDuplicate: true, variant: 'success' });
        }
    };

    const getDialogText = () => {
        if (isRetailerAdmin) {
            return <Trans i18nKey={'invite-manufacturer-dialog-text'} />;
        }
        if (isClinicAdmin) {
            return <Trans i18nKey={'invite-retailer-dialog-text'} />;
        }
    };

    const getDialogTitle = () => {
        if (isRetailerAdmin) {
            return t('invite-member-text', { name: t('member-type-manufacturer') });
        }
        if (isClinicAdmin) {
            return t('invite-member-text', { name: t('member-type-retailer') });
        }
    };

    return (
        <DialogBox>
            <Stack direction="column" spacing={0} alignItems="start" sx={{ padding: '20px', width: '100%', height: '100%' }}>
                <Stack><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }} >{getDialogTitle()}</Typography></Stack>
                <Stack sx={{ width: '100%' }}>
                    <Divider sx={{ paddingTop: '20px', width: '100%' }} />
                </Stack>
                <Stack pt="20px" pb="10px"><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }} >{t('invite-member-dialog-filed-label')}</Typography></Stack>
                <StyledTextField disabled sx={{ width: '100%' }} value={token}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end"> {
                                    <CopyIcon style={{ cursor: 'pointer' }} onClick={() => copyToClipboard()} />
                                }
                                </InputAdornment>)
                        }
                    }}
                />
                <Stack pt="20px" pb="20px">
                    <div>{getDialogText()}</div>
                </Stack>
                <Stack sx={{ width: '100%', height: '100%' }} alignItems="end" spacing={'20px'} direction="row">
                    <TheraButton fullWidth onClick={okCallback} size="medium" color="success" variant="contained">{'Ok'}</TheraButton>
                </Stack>
            </Stack >
        </DialogBox >
    );
};

export default InviteMemberDialog;
