import { TableCell, styled } from "@mui/material";
import theme, { largeFont, mediumFont } from "../../assets/theme";

export const HeaderTableCell = styled(TableCell)({
    fontSize: largeFont,
    padding: 0,
    userSelect: 'none',
    backgroundColor: theme.palette.secondary.main,
});

export const DataTableCell = styled(TableCell)({
    fontSize: mediumFont,
    padding: 0,
    userSelect: 'none',
});

export const ColumnLabel = styled('div')({
    fontSize: largeFont,
    fontWeight: 'bold',
    cursor: 'pointer'
});
