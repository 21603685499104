import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

type HistoryAction = {
    patientId?: string;
    sessionId: string;
    action: 'delete' | 'assign' | 'unassign';
};

interface HistoryState {
    historyAction?: HistoryAction;
}

const initialState: HistoryState = {
    historyAction: undefined
};

export const historyState = createSlice({
    name: 'history',
    initialState,
    reducers: {
        addHistoryAction: (state, { payload }) => {
            state.historyAction = payload;
        },
        clearHistory: (state) => {
            state.historyAction = undefined;
        },
    },
});

export const { addHistoryAction, clearHistory } = historyState.actions;
export const selectHistoryAction = (state: RootState) => state.history.historyAction;


