import { useEffect } from 'react';
import useFetchDevices from '../../../../hooks/useFetchDevices';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAccordionSessionId, selectCurrentPatientId, setAccordionSessionId } from '../../../../store/uiState';
import SingleColumn from '../../../PageTemplates/SingleColumn';
import TrainingSettingsPage from './TrainingSettingsPage';
import { getDeviceKey } from '../../../ResultAssignment/fragments/SessionDetailsBox';

const TrainingsSettings = () => {
    const dispatch = useAppDispatch();
    const patientId = useAppSelector(selectCurrentPatientId);
    const { isDevicesLoading, devices } = useFetchDevices();
    const accordionSessionId = useAppSelector(selectAccordionSessionId);

    useEffect(() => {
        if (!accordionSessionId && !isDevicesLoading && devices) {
            if (devices.length > 0) {
                dispatch(setAccordionSessionId(getDeviceKey(devices[0])));
            }
        }
    }, [accordionSessionId, isDevicesLoading, devices, dispatch]);

    if (isDevicesLoading) {
        return <></>;
    }

    const getComponent = () => {
        if (patientId) {
            return <TrainingSettingsPage devices={devices || []} patientId={patientId} />;
        }
        return <></>;
    };

    return (
        <SingleColumn component={getComponent()} />
    );

};

export default TrainingsSettings;
