import { Box } from '@mui/material';
import SingleColumn from '../PageTemplates/SingleColumn';
import PinnedPatientList from './fragments/PinnedPatientList';
import ButtonBar from '../User/fragments/ButtonBar';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import AssignedList from './fragments/AssignedList';
import UnAssignedList from './fragments/UnAssignedList';
import Avatar from '../Avatar/Avatar';
import theme, { largeFont } from '../../assets/theme';
import { useGetDeviceListQuery } from '../../api/deviceApi';
import { DeviceMessage } from '../../types/types';
import useFetchPatients from '../../hooks/useFetchPatients';
import useFetchUnassignedSessions from '../../hooks/useFetchUnassignedSessions';

export type SelectedPatient = {
    id: string;
    patientId: string;
    name: string;
};

const ResultAssignment = () => {
    const { isUnassignedSessionsLoading, unassignedSessions } = useFetchUnassignedSessions();
    const { isPatientsLoading, patients } = useFetchPatients();
    const { isLoading, data } = useGetDeviceListQuery();
    const [selectedPatient, setSelectedPatient] = useState<SelectedPatient>();
    const [scrollPos, setScrollPos] = useState(0);
    const [devices, setDevices] = useState<DeviceMessage[]>([]);

    useEffect(() => {
        if (!isLoading && data) {
            setDevices(() => data);
        }
    }, [isLoading, data]);

    if (isLoading || isPatientsLoading || isUnassignedSessionsLoading) {
        return <></>;
    }

    const showDetails = (patient: SelectedPatient, scrollPos: number) => {
        setScrollPos(scrollPos);
        setSelectedPatient(() => patient);
    };

    const renderContent = () => {
        return (
            <Box sx={{ display: 'flex', height: '100%' }} >
                <Box sx={{ width: '33%', minWidth: '450px' }} >
                    <Box style={{ paddingLeft: '20px', marginRight: '10px', height: '100%' }}>
                        <Box style={{
                            width: '100%',
                            paddingTop: '20px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            {t('result-assignment-list-title')}
                        </Box>

                        <ButtonBar
                            showFilter={false}
                            searchFieldPlaceholder={t('patient-searchfield-placeholder')}
                            addEntryLabel={t('user-add-button')}
                            showAddButton={false}
                            addNewEntry={() => { }} />

                        {patients && <PinnedPatientList patients={patients} scrollPos={scrollPos} selectedPatient={selectedPatient} showDetails={showDetails} />}
                    </Box>
                </Box>
                <Box sx={{ width: '33%' }}>
                    <Box style={{ paddingLeft: '10px', marginRight: '10px', height: '100%' }}>
                        <Box style={{
                            width: '100%',
                            paddingTop: '14px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            <Box style={{ display: 'flex' }}>
                                <Box style={{ paddingRight: '10px', alignContent: 'center' }}>
                                    <Avatar name={selectedPatient?.name} />
                                </Box>
                                <Box style={{ alignContent: 'center' }}>{t('assigned-sessions-header-title', { name: selectedPatient?.name })}</Box>
                            </Box>
                        </Box>
                        {selectedPatient && <AssignedList devices={devices} patientId={selectedPatient.patientId} />}
                    </Box>
                </Box >
                <Box sx={{ width: '33%' }}>
                    <Box style={{ paddingLeft: '10px', paddingRight: '10px', marginRight: '10px', height: '100%' }}>
                        <Box style={{
                            width: '100%',
                            paddingTop: '20px',
                            height: '67px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            {t('unassigned-sessions-header-title')}
                        </Box>

                        {selectedPatient && <UnAssignedList unassignedSessions={unassignedSessions} devices={devices} patientId={selectedPatient.patientId} />}
                    </Box>
                </Box>
            </Box >
        );
    };

    return (
        <SingleColumn component={renderContent()} />
    );

};

export default ResultAssignment;
