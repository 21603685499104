import { styled, Box, Dialog } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Avatar from "../Avatar/Avatar";
import { useAppDispatch, useAppSelector } from "../../store";
import { clearAccessToken, selectGivenName, selectSureName, selectUserRole } from "../../store/authSlice";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TwoArrowsDownIcon from "../../icons/TwoArrowsDownIcon";
import VersionsinfoDialog from "../CloudMember/ConfirmDialog/VersionsinfoDialog";
import { tinyFont } from "../../assets/theme";
import HeaderDropDown from "./fragments/HeaderDropDown";
import CloudMemberDetailsDialog from "../CloudMember/DetailsPanel/CloudMemberDetailsDialog";

const StyledDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '80px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: '43px',
}));

const ArrowDown = styled(TwoArrowsDownIcon)(({ theme }) => ({
    fontSize: tinyFont,
    '& path': {
        fill: theme.palette.secondary.main
    }
}));

export const PageHeader = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch();
    const dialogRef = useRef();
    const sureName = useAppSelector(selectSureName);
    const givenName = useAppSelector(selectGivenName);
    const role = useAppSelector(selectUserRole);
    const [showVersionsDialog, setShowVersionsDialog] = useState(false);
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const navigate = useNavigate();

    const logOut = () => {
        navigate('/');
        dispatch(clearAccessToken());
    };

    const showDropDownMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            return hideMenu();
        }
        setAnchorEl(dialogRef.current);
    };

    const hideMenu = () => {
        setAnchorEl(undefined);
    };

    return (
        <StyledDiv data-testid="page-header">
            <Grid container spacing={1} size={12} sx={{ paddingRight: '30px', alignItems: "center", justifyContent: "flex-end" }}>
                <Grid onClick={showDropDownMenu} sx={{ cursor: 'pointer' }}>
                    <Avatar name={`${givenName} ${sureName}`.trim()} />
                </Grid>
                <Grid onClick={showDropDownMenu} sx={{ cursor: 'pointer' }} pr={0}>
                    <Box ref={dialogRef} style={{ display: 'flex' }}>
                        <ArrowDown />
                    </Box>
                    <HeaderDropDown open={open} hideMenu={hideMenu} anchorEl={anchorEl} givenName={givenName} sureName={sureName} role={role} setShowSettingsDialog={setShowSettingsDialog} setShowVersionsDialog={setShowVersionsDialog} logOut={logOut} />
                </Grid>
            </Grid>
            <Dialog maxWidth={'lg'} onClose={() => setShowVersionsDialog(false)} open={showVersionsDialog}><VersionsinfoDialog okCallback={() => { setShowVersionsDialog(false); }} /></Dialog>
            <Dialog maxWidth={'lg'} onClose={() => setShowSettingsDialog(false)} open={showSettingsDialog}><Box sx={{ minWidth: '500px' }}><CloudMemberDetailsDialog okCallback={() => { setShowSettingsDialog(false); }} /></Box></Dialog>
        </StyledDiv>
    );
};
