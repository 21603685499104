import { useEffect, useState } from "react";
import { useGetClinicTokenMutation } from "../../../../api/clinicApi";
import InviteMemberDialog from "./InviteMemberDialog";
import { isEmpty } from "../../../ResultAssignment/fragments/SessionDetailsBox";

type DialogProps = {
    okCallback: () => void;
};
const InviteRetailerDialog = ({ okCallback }: DialogProps) => {
    const [getToken] = useGetClinicTokenMutation();
    const [token, setToken] = useState('');

    useEffect(() => {
        if (isEmpty(token)) {
            getToken().then((result: any) => {
                if ('data' in result) {
                    if ('token' in result.data) {
                        setToken(() => result.data.token);
                    }
                }
            });
        }
    }, [getToken, token]);

    return <InviteMemberDialog okCallback={okCallback} token={token} />;
};

export default InviteRetailerDialog;
