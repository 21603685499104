import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import { selectAuthState, selectIsClinicAdmin, selectIsManufacturerAdmin, selectIsRetailerAdmin } from "../../../store/authSlice";
import EditCloudMember from "./EditCloudMember";
import { CloudMember } from "../../../types/types";
import { useFetchManufacturerMutation, useUpdateManufacturerMutation } from "../../../api/manufacturerApi";
import { useFetchRetailerMutation, useUpdateRetailerMutation } from "../../../api/retailerApi";
import { useFetchClinicMutation, useUpdateClinicMutation } from "../../../api/clinicApi";
import ManufacturerDetails from "../../Manufacturer/fragments/ManufacturerDetails";
import ClinicDetails from "../../Clinic/fragments/ClinicDetails";
import RetailerDetails from "../../Retailer/fragments/RetailerDetails";
import { Box, styled } from "@mui/material";
import theme, { largeFont } from "../../../assets/theme";

const DialogBox = styled(Box)({
    maxHeight: `${window.innerHeight - 80}px`,
    width: '100%',
    display: 'inline-flex',
    overflow: 'auto',
    zIndex: 99,
    padding: '10px',
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont,
});
type DialogProps = {
    okCallback: () => void;
};
const CloudMemberDetailsDialog = ({ okCallback }: DialogProps) => {
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const authState = useAppSelector(selectAuthState);
    const [getManufacturer] = useFetchManufacturerMutation();
    const [getRetailer] = useFetchRetailerMutation();
    const [getClinic] = useFetchClinicMutation();
    const [updateManufacturer] = useUpdateManufacturerMutation();
    const [updateRetailer] = useUpdateRetailerMutation();
    const [updateClinic] = useUpdateClinicMutation();

    const [data, setData] = useState<CloudMember>();
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (isManufacturerAdmin && authState.manufacturerId) {
            getManufacturer(authState.manufacturerId).then(result => {
                setData(result.data);
            });
        }
        if (isRetailerAdmin && authState.retailerId) {
            getRetailer(authState.retailerId).then(result => {
                setData(result.data);
            });
        }
        if (isClinicAdmin && authState.clinicId) {
            getClinic(authState.clinicId).then(result => {
                setData(result.data);
            });
        }
    }, [authState, getClinic, getRetailer, getManufacturer, isManufacturerAdmin, isRetailerAdmin, isClinicAdmin]);

    if (!data) {
        return <div>No data found!</div>;
    }

    const getShowDialog = () => {
        if (isManufacturerAdmin) {
            return <ManufacturerDetails
                setPage={() => { setPage(() => 1); }}
                manufacturerId={data.id}
            />;
        }
        if (isRetailerAdmin) {
            return <RetailerDetails
                setPage={() => { setPage(() => 1); }}
                retailerId={data.id}
            />;
        }
        if (isClinicAdmin) {
            return <ClinicDetails
                setPage={() => { setPage(() => 1); }}
                clinicId={data.id}
            />;
        }
        return 'error';
    };

    const update = async (params: any) => {
        if (isManufacturerAdmin) {
            updateManufacturer(params);
        }
        if (isRetailerAdmin) {
            updateRetailer(params);
        }
        if (isClinicAdmin) {
            updateClinic(params);
        }
        okCallback();
    };

    const getEditDialog = () => {
        if (isManufacturerAdmin) {
            return <EditCloudMember data={data}
                setPage={okCallback}
                update={update}
            />;
        }
        if (isRetailerAdmin) {
            return <EditCloudMember data={data}
                setPage={okCallback}
                update={update}
            />;
        }
        if (isClinicAdmin) {
            return <EditCloudMember data={data}
                setPage={okCallback}
                update={update}
            />;
        }
        return 'error';
    };

    return <DialogBox>
        {page === 0 ? getShowDialog() : getEditDialog()}
    </DialogBox>;
};

export default CloudMemberDetailsDialog;
