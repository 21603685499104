import { SetStateAction, useEffect, useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { Pages } from "../../CloudMember/MemberListPanels/listPanelGlobals";
import { Box, Divider, Grid2 as Grid, Stack } from "@mui/material";
import Avatar from "../../Avatar/Avatar";
import { useGetUserQuery, useUpdateUserMutation } from "../../../api/userApi";
import { t } from "i18next";
import StyledTextField from "../../StyledTextField/StyledTextField";
import StyledMultilineField from "../../StyledTextField/StyledMultilineField";
import { UserUpdate } from "../../../types/types";
import StyledText from "../../StyledTextField/StyledText";
import StyledLabel from "../../StyledTextField/StyledLabel";
import FooterText from "../../StyledTextField/FooterText";
import TheraButton from "../../TheraButtons/TheraButton";
import { dateString, roleName } from "../../ResultAssignment/fragments/SessionDetailsBox";

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    userId: string;
};

const EditUser = ({ userId, setPage }: DetailsProps) => {
    const username = useRef<HTMLInputElement>();
    const email = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();
    const givenName = useRef<HTMLInputElement>();
    const sureName = useRef<HTMLInputElement>();
    const password = useRef<HTMLInputElement>();

    const { data, isLoading } = useGetUserQuery(userId);//, { refetchOnMountOrArgChange: true });
    const [updateUser, { error }] = useUpdateUserMutation();
    const name = `${data?.givenName} ${data?.sureName}`;

    useEffect(() => {
        if (error) {
            enqueueSnackbar(ErrorMessage(error), { variant: 'error' });

        }
    }, [error]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (data) {
            const params: UserUpdate = {
                id: data.id,
                username: data.username,
                email: email.current?.value ?? '',
                comment: comment.current?.value ?? '',
                sureName: sureName.current?.value ?? '',
                givenName: givenName.current?.value ?? '',
                password: password.current?.value ?? ''
            };
            updateUser(params);
            setPage(Pages.DETAILS);
        };
    };

    return (
        <form onSubmit={handleSubmit} style={{ padding: '20px', height: '100%' }}>
            <Box sx={{ height: '100%' }} >
                <Stack direction="column" sx={{ paddingRight: '15px', height: '100%', overflow: 'auto' }} >

                    <Stack sx={{ display: 'flex', paddingBottom: '20px', width: '100%', }} direction="row">
                        <div style={{ marginRight: '20px' }}><Avatar size="large" name={name} /></div>
                        <Stack direction="column" sx={{ width: '100%' }}>
                            <StyledLabel>{name}</StyledLabel>
                            <StyledLabel sx={{ fontWeight: 'normal' }}>{t('member-type-user')}</StyledLabel>
                        </Stack>
                    </Stack >

                    <Divider />

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '0px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                        <StyledMultilineField
                            variant="outlined"
                            color="primary"
                            multiline
                            rows={3}
                            defaultValue={data?.comment}
                            inputRef={comment}
                        />
                    </Stack>

                    <Divider sx={{ marginTop: '20px' }} />

                    <Stack pt="20px" direction="row" spacing={2}>
                        <StyledLabel sx={{ width: '50%' }}>{t('cloud-member-form-sure-name-label')}</StyledLabel>
                        <StyledLabel>{t('cloud-member-form-given-name-label')}</StyledLabel>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={sureName}
                            defaultValue={data?.sureName}
                        />
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={givenName}
                            defaultValue={data?.givenName}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-email-label')}</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={email}
                            defaultValue={data?.email}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingBottom: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-username-label')}</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={username}
                            disabled
                            defaultValue={data?.username}
                        />
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '0px', paddingBottom: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-password-label')}</StyledLabel>
                        <StyledTextField
                            sx={{ paddingLeft: '0px' }}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            type="text"
                            inputRef={password}
                        />

                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '0px', paddingBottom: '0px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-role-label')}</StyledLabel>
                        <StyledText>{data?.role ? roleName(data?.role) : ''}</StyledText>
                    </Stack>

                    <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '25px' }} >
                        <Stack spacing={'20px'} direction="row">
                            <TheraButton sx={{ width: '50%' }} onClick={() => setPage(Pages.DETAILS)} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                            <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                        </Stack>
                        <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
                        <Grid container>
                            <Grid size={6}>
                                <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                            </Grid>
                            <Grid size={6} sx={{ textAlign: 'right' }}>
                                <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                            </Grid>
                            <Grid size={6}>
                                <FooterText>{dateString(data?.lastUpdated ?? data?.created)}</FooterText>
                            </Grid>
                            <Grid size={6} sx={{ textAlign: 'right' }}>
                                <FooterText>{dateString(data?.created)}</FooterText>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Box>
        </form>
    );
};

export default EditUser;
