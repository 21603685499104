import { Grid } from '@mui/material';
import SingleColumn from '../PageTemplates/SingleColumn';
import useFetchManufacturers from '../../hooks/useFetchManufacturer';
import useFetchRetailers from '../../hooks/useFetchRetailers';
import useFetchClinics from '../../hooks/useFetchClinics';
import useFetchDevices from '../../hooks/useFetchDevices';
import { useGetUserListQuery } from '../../api/userApi';

const DeveloperPage = () => {
    const { data, isLoading } = useGetUserListQuery();
    const { manufacturers, isManufacturersLoading } = useFetchManufacturers();

    const { retailers, isRetailersLoading } = useFetchRetailers();

    const { clinics, isClinicsLoading } = useFetchClinics();

    const { devices, isDevicesLoading } = useFetchDevices();

    if (isLoading || isManufacturersLoading || isRetailersLoading || isClinicsLoading || isDevicesLoading) {
        return <></>;
    }

    const renderPage = () => {
        return (
            <Grid container item padding="20px" xs={6} style={{}}>
                <Grid item xs={12} paddingBottom="10px">
                    {manufacturers?.length} Hersteller gefunden.
                </Grid>

                <Grid item xs={12} paddingBottom="10px" paddingTop="20px">
                    {retailers?.length} Händler gefunden.
                </Grid>

                <Grid item xs={12} paddingBottom="10px" paddingTop="20px">
                    {clinics?.length} Kliniken gefunden.
                </Grid>

                <Grid item xs={12} paddingBottom="10px" paddingTop="20px">
                    {devices?.length} Geräte gefunden.
                </Grid>

                <Grid item xs={12} paddingBottom="10px" paddingTop="20px">
                    {data?.length} Benuzter gefunden.
                </Grid>
            </Grid>
        );
    };
    return (
        <SingleColumn component={renderPage()} />
    );

};

export default DeveloperPage;
