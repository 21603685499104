import { Checkbox, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetLockedUserListQuery } from '../../../api/userApi';
import theme from '../../../assets/theme';
import { DataTableCell, HeaderTableCell } from '../../StyledTable/StyledTableElements';
import { dateString } from '../../ResultAssignment/fragments/SessionDetailsBox';
import { t } from 'i18next';

type LockedAccountsListProps = {
    filter: string;
    unlockAccounts: (selectedAccounts: string[]) => void;
};

const LockedAccountsList = ({ filter, unlockAccounts }: LockedAccountsListProps) => {
    const { isLoading, data } = useGetLockedUserListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [itemsChecked, setItemsChecked] = useState(false);

    useEffect(() => {
        setSelectedAccounts((selectedAccounts) => selectedAccounts.filter((account) => account.indexOf(filter) >= 0));
    }, [filter]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedAccounts([...selectedAccounts, value]);
            unlockAccounts([...selectedAccounts, value]);
        } else {
            setSelectedAccounts(selectedAccounts.filter((item) => item !== value));
            unlockAccounts(selectedAccounts.filter((item) => item !== value));
        }
    };

    const selectDeselectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!data) {
            return;
        }
        const { checked } = event.target;
        const selected: string[] = [];
        if (checked) {
            const filteredAccounts = data.filter((user) => user.username.indexOf(filter) >= 0);
            filteredAccounts.forEach(account => { selected.push(account.username); });
        }
        setSelectedAccounts(selected);
        setItemsChecked(checked);
        unlockAccounts(selected);
    };

    return (
        <Table size="small" sx={{ marginTop: '40px' }}>
            <TableHead>
                <TableRow key="table-columns-header" sx={{ height: '60px' }}>
                    <HeaderTableCell sx={{ textAlign: 'left', paddingLeft: 0, width: '40px' }}><Typography><Checkbox sx={{ padding: 0, color: theme.palette.primary.main }}
                        checked={itemsChecked} onChange={selectDeselectAll.bind(this)} /></Typography></HeaderTableCell>
                    <HeaderTableCell>{t('cloud-member-form-name-label')}</HeaderTableCell>
                    <HeaderTableCell width={50}>Last Failure</HeaderTableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{}}>
                {data?.filter((user) => user.username.indexOf(filter) >= 0)
                    .map((user) => {
                        return (
                            <TableRow hover key={user.username} sx={{ height: '60px' }}>
                                <DataTableCell sx={{ textAlign: 'left', padding: 0, }}>
                                    <Checkbox sx={{ padding: 0, color: theme.palette.linkgray.main }} checked={selectedAccounts.includes(user.username)} onChange={handleCheckboxClick}
                                        value={user.username} /></DataTableCell>
                                <DataTableCell>{user.username}</DataTableCell>
                                <DataTableCell width="200px">{dateString(user.lastFailureDate)}</DataTableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table >);
};

export default LockedAccountsList;
