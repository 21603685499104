import { Stack, Grid2 as Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Divider, styled } from "@mui/material";
import { DeviceLog } from "../../../types/types";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, tinyFont } from "../../../assets/theme";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";
import { useDownloadDeviceLogMutation, useGetDeviceLogsQuery } from "../../../api/deviceApi";
import DownloadIcon from "../../../icons/DownloadIcon";
import { addDays, format, startOfWeek } from "date-fns";
import { SortState } from "../DeviceLog";
import { iso8601ToDate } from "../../ResultAssignment/fragments/SessionDetailsBox";
import DateRangePicker, { DateRange } from "rsuite/esm/DateRangePicker";
import { useState } from "react";

const ButtonBox = styled('div')({
    paddingTop: '5px',
    textAlign: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.lightgreen.main,
    width: '40px',
    height: '35px',
    borderRadius: '5px',
});

const sortData = (sortField: string, sortOrder: string, data: DeviceLog[]) => {
    if (!data) {
        return [];
    }
    return data.slice().sort((a, b) => {
        const order = sortOrder === 'up' ? 1 : -1;
        if (sortField === 'size') {
            if (a.size > b.size) {
                return order;
            }
            if (a.size < b.size) {
                return -order;
            }
        } else {
            if (a.created > b.created) {
                return order;
            }
            if (a.created < b.created) {
                return -order;
            }
        }
        return 0;
    });
};

type ListProps = {
    deviceId: string;
    setSortState: (state: SortState) => void;
    sortState?: SortState;
};

const LogsList = ({ sortState, setSortState, deviceId }: ListProps) => {
    const { data, isLoading } = useGetDeviceLogsQuery(deviceId, { refetchOnMountOrArgChange: true });
    const [downloadLogFile] = useDownloadDeviceLogMutation();
    const [dateRange, setDateRange] = useState<DateRange | undefined>([startOfWeek(new Date()), new Date()]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const timeString = (dateString: string) => {
        if (dateString) {
            const date = new Date(dateString);
            return format(date, 'dd.MM.yyyy HH:mm ') + t('therapy-session-time-label');
        }
        return '';
    };

    const downloadFile = (log: DeviceLog) => {
        downloadLogFile(log.id);
    };

    const toggleColumnSearch = (field: string) => {
        if (sortState && sortState.field === field) {
            const order = sortState.order === 'up' ? 'down' : 'up';
            setSortState({ field, order });
            return;
        }

        setSortState({ field, order: 'up' });
    };

    const getOpacity = (field: string, order: string) => {
        if (sortState && field === sortState.field && sortState.order === order) {
            return 1;
        }
        return 0.3;
    };

    const getFilteredLogs = () => {
        if (!data) {
            return [];
        }

        if (dateRange) {
            return data.filter((log) => {
                const start = iso8601ToDate(log.created);
                start.setHours(0, 0, 0, 0);
                dateRange[0].setHours(0, 0, 0, 0);
                dateRange[1].setHours(0, 0, 0, 0);
                if (start.getTime() >= dateRange[0].getTime() && start.getTime() <= dateRange[1].getTime()) {
                    return log;
                }
                return undefined;
            });
        }
        return data;
    };

    const getSortedList = () => {
        if (sortState) {
            return sortData(sortState.field, sortState.order, getFilteredLogs());
        }

        return getFilteredLogs();
    };

    const getSortedColumnHeader = (label: string, column: string) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    const selectDateRange = (e: DateRange | null) => {
        if (e) {
            setDateRange(() => [e[0], e[1]]);
            return;
        }
        setDateRange(() => undefined);
    };

    return (<>
        <Stack sx={{ padding: '20px', display: 'flex', justifyContent: 'space-between', overflow: 'auto', width: '100%' }} direction="row">
            <Stack sx={{ justifyContent: 'center' }}>
                <Typography fontSize={largeFont} fontWeight="bold">Logfiles</Typography>
            </Stack>
            <Stack>
                <DateRangePicker
                    placement='bottomEnd'
                    onChange={selectDateRange}
                    showOneCalendar
                    character=" - "
                    size="lg"
                    editable={false}
                    format="dd.MM.yyyy"
                    placeholder="Zeitraum auswählen"
                    defaultValue={dateRange}
                    ranges={[
                        {
                            label: 'Heute',
                            value: [new Date(), new Date()],
                            placement: 'left'
                        },
                        {
                            label: 'Diese Woche',
                            value: [startOfWeek(new Date()), new Date()],
                            placement: 'left'
                        },
                        {
                            label: 'Letzen 14 Tage',
                            value: [addDays(new Date(), -14), new Date()],
                            placement: 'left'
                        },
                    ]} />
            </Stack>
        </Stack >
        <Divider />
        <TableContainer sx={{ height: 'calc(100% - 90px)' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>

                        <HeaderTableCell sx={{}}>
                            {getSortedColumnHeader(t('log-list-date-header'), "date")}
                        </HeaderTableCell>

                        <HeaderTableCell sx={{}}>
                            {getSortedColumnHeader(t('log-list-size-header'), "size")}
                        </HeaderTableCell>

                        <HeaderTableCell sx={{ minWidth: '40px' }}></HeaderTableCell>

                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getSortedList().map(log => {
                        return (<StyledTableRow key={log.id} sx={{ height: '80px' }} >
                            <DataTableCell sx={{ width: '20px' }}></DataTableCell>

                            <DataTableCell>
                                <Typography fontSize={'13pt'} pl={'5px'}>{timeString(log.created)}</Typography>
                            </DataTableCell>

                            <DataTableCell>
                                <Typography fontSize={'13pt'} pl={'10px'}>{log.size + ' bytes'}</Typography>
                            </DataTableCell>

                            <DataTableCell sx={{ textAlign: 'right' }}>
                                <ButtonBox onClick={() => downloadFile(log)} style={{ cursor: 'pointer', marginLeft: '20px' }}>
                                    <DownloadIcon sx={{ fontSize: '25px' }} />
                                </ButtonBox>
                            </DataTableCell>

                            <DataTableCell sx={{ width: '20px' }}></DataTableCell>
                        </StyledTableRow>
                        );
                    })
                    }
                </TableBody>
            </Table >
        </TableContainer >
    </>
    );
};
export default LogsList;
