import { Box, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import SingleColumn from '../../../PageTemplates/SingleColumn';
import UnAssignedList from '../../../ResultAssignment/fragments/UnAssignedList';
import AssignedList from '../../../ResultAssignment/fragments/AssignedList';
import theme, { largeFont } from '../../../../assets/theme';
import { DeviceMessage } from '../../../../types/types';
import { useGetDeviceListQuery } from '../../../../api/deviceApi';
import useFetchUnassignedSessions from '../../../../hooks/useFetchUnassignedSessions';
import PatientDetails from '../PatientInfo/PatientDetails';
import { useAppSelector } from '../../../../store';
import { selectCurrentPatientId } from '../../../../store/uiState';
import AssignedListHeader from './fragments/AssignedListHeader';
import { t } from 'i18next';
import PatientIdAvatar from '../PatientIdAvatar/PatientIdAvatar';

const ColumnGrid = styled(Grid)({
    borderRadius: '10px',
    height: '100%',
    overflow: 'auto',
});

export type SelectedPatient = {
    id: string;
    patientId: string;
    name: string;
};

const PatientResultAssignment = () => {
    const patientId = useAppSelector(selectCurrentPatientId);
    const { isUnassignedSessionsLoading, unassignedSessions } = useFetchUnassignedSessions();
    const { isLoading, data } = useGetDeviceListQuery();
    const [devices, setDevices] = useState<DeviceMessage[]>([]);

    useEffect(() => {
        if (!isLoading && data) {
            setDevices(() => data);
        }
    }, [isLoading, data]);

    if (isLoading || isUnassignedSessionsLoading) {
        return <></>;
    }


    const getLeftComponent = () => {
        if (patientId) {
            return <PatientDetails patientId={patientId} />;
        }
    };

    const renderPageContent = () => {
        return (
            <Grid container direction="row" sx={{ height: '100%' }} spacing={0}>
                <ColumnGrid size={4} >
                    {getLeftComponent()}
                </ColumnGrid>
                <ColumnGrid size={4} >
                    <Box style={{ paddingLeft: '10px', paddingRight: '0px', marginRight: '10px', height: '100%' }}>
                        <Box style={{
                            width: '100%',
                            paddingTop: '20px',
                            height: '80px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            <Box style={{ display: 'flex' }}>
                                <Box style={{ paddingRight: '10px', alignContent: 'center' }}>
                                    <PatientIdAvatar patientId={patientId ?? '??'} />
                                </Box>
                                {patientId && <AssignedListHeader patientId={patientId} />}
                            </Box>
                        </Box>
                        {patientId && <AssignedList devices={devices} patientId={patientId} />}
                    </Box>
                </ColumnGrid >
                <ColumnGrid size={4}>
                    <Box style={{ paddingLeft: '10px', paddingRight: '10px', marginRight: '10px', height: '100%' }}>
                        <Box style={{
                            width: '100%',
                            paddingTop: '25px',
                            height: '80px',
                            fontWeight: 'bold',
                            borderBottom: `solid 1px ${theme.palette.linkgray.main}`,
                            fontSize: largeFont
                        }}>
                            {t('unassigned-sessions-header-title')}
                        </Box>

                        {patientId && <UnAssignedList unassignedSessions={unassignedSessions} devices={devices} patientId={patientId} />}
                    </Box>
                </ColumnGrid>
            </Grid >
        );
    };

    return (
        <SingleColumn component={renderPageContent()} />
    );

};

export default PatientResultAssignment;
