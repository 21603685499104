import { DeviceMessage } from '../../../../types/types';
import { JSX } from 'react/jsx-runtime';
import { getDeviceKey } from '../../../ResultAssignment/fragments/SessionDetailsBox';
import DeviceTypeAccordion from './DeviceTypeAccordion';
import { useCallback } from 'react';
import { selectAccordionSessionId, setAccordionSessionId } from '../../../../store/uiState';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { Grid2 as Grid, Box, Divider } from '@mui/material';
import LastSessionData from './LastSessionData';
import TrainingsSettingsAvatar from '../PatientAvatar/TrainingsSettingsAvatar';
import { useGetPatientQuery } from '../../../../api/patientApi';
import useFetchPatients from '../../../../hooks/useFetchPatients';
import theme from '../../../../assets/theme';

type Props = {
    patientId: string;
    devices: DeviceMessage[];
};

const TrainingSettingsPage = ({ devices, patientId }: Props) => {
    const dispatch = useAppDispatch();
    const accordionSessionId = useAppSelector(selectAccordionSessionId);
    const { isPatientsLoading, patients } = useFetchPatients();
    const { data, isLoading } = useGetPatientQuery(patientId);

    const setExpandedSession = useCallback((session?: string) => {
        dispatch(setAccordionSessionId(session));
    }, [dispatch]);

    if (isPatientsLoading || !patients || isLoading || !data) {
        return <></>;
    }

    const getAccordions = () => {
        const deviceKeys = devices.map(device => getDeviceKey(device));
        const unique = [...new Set(deviceKeys)];

        let accordion: JSX.Element[] = [];
        unique.forEach(deviceKey => {
            const entry =
                <DeviceTypeAccordion
                    patients={patients}
                    patient={data}
                    key={deviceKey}
                    name={deviceKey}
                    devices={devices}
                    handleChange={() => handleChange(deviceKey)} />;
            accordion.push(entry);
        });
        return accordion;
    };

    const handleChange = (panel: string) => {
        if (accordionSessionId !== panel) {
            setExpandedSession(panel);
        }
    };

    return (
        <Box style={{ height: 'calc(100% - 10px)', padding: '20px 20px 10px 20px', overflow: 'auto' }}>
            <Grid container sx={{ height: '100%' }}>
                <Grid size={7} sx={{ paddingRight: '20px' }}>
                    <TrainingsSettingsAvatar patient={data} />
                    <Divider />
                    {getAccordions()}
                </Grid>
                <Grid size={5} sx={{ backgroundColor: theme.palette.darkgreen.contrastText, borderRadius: '5px' }}>
                    <LastSessionData devices={devices} patient={data} />
                </Grid>
            </Grid>
        </Box >
    );
};

export default TrainingSettingsPage;;
