import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

interface ListState {
    filter: string;
    order?: string;
    field?: string;
}

const initialState: ListState = {
    filter: ''
};

export const listStateSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        setListFilter: (state, { payload }) => {
            state.filter = payload;
        },
        setListOrderState: (state, { payload }: PayloadAction<{ order: string, field: string; }>) => {
            state.field = payload.field;
            state.order = payload.order;
        },
        resetListState: (state) => {
            state.field = undefined;
            state.order = undefined;
            state.filter = '';
        },
    },
});

export const { setListFilter, setListOrderState, resetListState } = listStateSlice.actions;
export const selectListFilter = (state: RootState) => state.list.filter;
export const selectListOrder = (state: RootState) => state.list.order;
export const selectListField = (state: RootState) => state.list.field;


