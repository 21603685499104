import { useEffect, useState } from "react";
import { CloudMember } from "../types/types";
import { useGetClinicListQuery } from "../api/clinicApi";

const useFetchClinics = () => {
    const { isLoading, data, error } = useGetClinicListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [clinics, setClinics] = useState<CloudMember[]>();
    const [isClinicsLoading, setIsClinicsLoading] = useState(false);
    const [clinicsError, setClinicsError] = useState<string | null>(null);

    useEffect(() => {
        setIsClinicsLoading(isLoading);
        if (error) {
            setIsClinicsLoading(false);
            setClinicsError(JSON.stringify(error));
        } else if (!isLoading && data) {
            if (JSON.stringify(data) !== JSON.stringify(clinics)) {
                setClinics(data);
                setClinicsError(null);
            }
            setIsClinicsLoading(false);
        }
    }, [clinics, isLoading, data, error]);

    return { isClinicsLoading, clinics, clinicsError };

};

export default useFetchClinics;
