import { useState } from "react";
import { useUpdateTherapySessionDataMutation } from "../../../api/therapySessionsApi";
import { DeviceMessage, MappedTherapySessionData, } from "../../../types/types";
import SessionDetailsBox, { BoxType } from "./SessionDetailsBox";
import useFetchAssignedSessions from "../../../hooks/useFetchAssignedSessions";
import NoSessionFoundMessage from "../../Patients/fragments/NoSessionFoundMessage";
import EditDialog from "./EditDialog";
import { Stack } from "@mui/material";

export const sortedSessionsByStartdate = (data?: MappedTherapySessionData[]) => {
    if (!data) {
        return [];
    }

    return data.slice().sort((a, b) => {
        if (a.exerciseStartDate > b.exerciseStartDate) {
            return -1;
        }
        if (a.exerciseStartDate < b.exerciseStartDate) {
            return 1;
        }
        return 0;
    });

};

export interface Props {
    patientId: string;
    devices: DeviceMessage[];
}

const AssignedList = ({ devices, patientId }: Props) => {
    const { isAssignedSessionsLoading, assignedSessions } = useFetchAssignedSessions(patientId);
    const [updateThreapySession] = useUpdateTherapySessionDataMutation();
    const [sessionToEdit, setSessionToEdit] = useState<MappedTherapySessionData | undefined>();

    if (isAssignedSessionsLoading) {
        return <></>;
    }

    const unassignSession = (sessionId: string) => {
        const request = {
            sessionId,
            patientId: '',
            comment: '',
            trainingsGoal: '',
            edited: false,
            tlResult5: '',
            tlResult6: '',
            tlResult7: '',
            tlResult8: '',

        };
        updateThreapySession(request).then(() => {
        });

    };

    return (
        <Stack direction="column" sx={{
            height: 'calc(100% - 110px)',
            overflow: 'auto',
            width: '100%',
            marginTop: '20px',
            paddingRight: '10px'
        }}>
            {
                assignedSessions.length > 0 ?
                    sortedSessionsByStartdate(assignedSessions).map((session: MappedTherapySessionData) => {
                        const device = devices.find((device) => device.serial === session.serial);
                        return <SessionDetailsBox
                            label={device?.name ?? session.type}
                            boxType={BoxType.ASSIGNED}
                            editSession={() => { setSessionToEdit(session); }}
                            deleteSession={() => { }}
                            onArrowClick={unassignSession}
                            key={session.id}
                            session={session} />;
                    }
                    ) : <NoSessionFoundMessage showBorder={false} />
            }
            {sessionToEdit && <EditDialog onClose={() => { setSessionToEdit(undefined); }} session={sessionToEdit} patientId={patientId} />}
        </Stack>
    );
};

export default AssignedList;
