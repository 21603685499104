import { Box, Stack, styled, Typography } from "@mui/material";
import { DeviceMessage, Patient } from "../../../../types/types";
import theme, { smallFont } from "../../../../assets/theme";
import LyraIcon from "../../../../icons/LyraIcon";
import { getFullNameOrCode } from "../../../ResultAssignment/fragments/PinnedPatientList";
import { t } from "i18next";
import CloseIcon from "../../../../icons/CloseIcon";
import ConfirmDialog from "../../../CloudMember/ConfirmDialog/ConfirmDialog";
import { useRef, useState } from "react";
import AttentionIcon from "../../../../icons/AttentionIcon";
import SpeechBubble from "../../../SpeechBubble/SpeechBubble";
import { Trans } from "react-i18next";


const DeviceElement = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '5px',
    marginRight: '10px',
    marginTop: '10px',
    height: '100px',
    minWidth: '200px',
    borderRadius: '5px',
    boxShadow: '1px 3px 6px #00000029',

});

type Props = {
    device: DeviceMessage;
    patients: Patient[];
    patient: Patient;
    setCurrentDevice: (device: DeviceMessage | undefined) => void;
    setClearPatient: (device: DeviceMessage) => void;
};

const DeviceBox = ({ setClearPatient, setCurrentDevice, device, patients, patient }: Props) => {
    const [clearDevice, setClearDevice] = useState<DeviceMessage>();
    const ref = useRef<HTMLDivElement>();
    const [infoAnchor, setInfoAnchor] = useState<HTMLDivElement>();
    const infoOpen = Boolean(infoAnchor);

    const setSelectedDevice = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, device: DeviceMessage) => {
        setCurrentDevice(device);
    };

    const clearSelectedDevice = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, device: DeviceMessage) => {
        setInfoAnchor(undefined);
        setCurrentDevice(undefined);
    };

    const clearSelectedPatient = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, device: DeviceMessage) => {
        setInfoAnchor(() => ref.current);
        setClearPatient(device);
    };

    const renderDeviceBox = () => {
        if (device.patientId) {
            if (device.patientId === patient.id) {
                return (
                    <DeviceElement key={device.serial}
                        ref={ref}
                        sx={{ backgroundColor: theme.palette.darkgreen.contrastText, border: `solid 2px ${theme.palette.darkgreen.main}` }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{
                                display: 'flex',
                            }}>
                                <LyraIcon />
                            </Box>
                            <Box sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                                onClick={(e) => setSelectedDevice(e, device)}>
                                <Box sx={{ paddingRight: '3px' }}><CloseIcon sx={{ fontSize: smallFont }} /></Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                        }}>
                            {device.name}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                        }}>
                            <Typography fontSize={smallFont}>
                                {patients.map(p => p.id === device.patientId ? t('device-assigned-label', { name: getFullNameOrCode(p) }) : '')}
                            </Typography>
                        </Box>
                    </DeviceElement >);
            }
            return (
                <DeviceElement key={device.serial}
                    ref={ref}
                    onClick={(e) => clearSelectedDevice(e, device)}
                    sx={{ cursor: 'pointer', border: `solid 2px ${theme.palette.linkgray.main}` }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{
                            display: 'flex',
                        }}>
                            <LyraIcon />
                        </Box>
                        <Box sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                            onClick={(e) => setSelectedDevice(e, device)}>
                            <Box sx={{ paddingRight: '3px' }}><AttentionIcon fill="#0000ff" sx={{ fontSize: '16pt' }} /></Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        {device.name}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Box>
                            <Typography fontSize={smallFont}>
                                <CloseIcon sx={{ fontSize: '7pt' }} /> {patients.map(p => p.id === device.patientId ? t('device-assigned-label', { name: getFullNameOrCode(p) }) : '')}
                            </Typography>
                        </Box>
                    </Box>
                </DeviceElement>);
        }
        return (
            <DeviceElement key={device.serial} onClick={(e) => { clearSelectedPatient(e, device); }}
                ref={ref}
                sx={{
                    cursor: 'pointer', border: `solid 2px ${theme.palette.linkgray.main}`
                }}>
                <Box sx={{
                    display: 'flex',
                }}>
                    <LyraIcon />
                </Box>
                <Box sx={{
                    display: 'flex',
                }}>
                    {device.name}
                </Box>

                <Box sx={{
                    display: 'flex',
                }}>
                    <Typography fontSize={smallFont}>&nbsp;</Typography>
                </Box>

            </DeviceElement>);
    };

    const getTop = () => {
        const t = infoAnchor?.offsetTop ?? 0;
        const h = infoAnchor?.clientHeight ?? 0;
        const top = t - h;
        return top;
    };

    const getLeft = () => {
        const left = infoAnchor?.offsetLeft ?? 0;
        return left;
    };

    return <>{renderDeviceBox()}
        {clearDevice && <ConfirmDialog title={t('device-remove-assignment-title')}
            isVisible={clearDevice !== undefined}
            message={t('device-remove-assignment-message')}
            okCallback={() => { setClearPatient(clearDevice); setClearDevice(undefined); }}
            cancelCallback={() => { setClearDevice(undefined); }} />}
        {infoOpen && (device.patientId === patient.id) &&
            <SpeechBubble ignoreFirstClick={true} setInfoAnchor={setInfoAnchor} top={getTop()} left={getLeft()} child={
                <Stack direction="row" sx={{ margin: '15px' }}>
                    <Box sx={{ paddingTop: '5px', paddingRight: '20px' }}><Trans i18nKey={'start-session-info'} /></Box>
                    <Box textAlign={"end"} sx={{ cursor: 'pointer' }} onClick={() => setInfoAnchor(undefined)}>
                        <CloseIcon sx={{ fontSize: smallFont }} />
                    </Box>
                </Stack>
            } />
        }
    </>;

};

export default DeviceBox;
