import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import TheraButton from "../../TheraButtons/TheraButton";
import { useGetGlobalInfoQuery } from "../../../api/globalInfoApi";
import { VERSION } from "../../../config/constanst";
import theme, { largeFont } from "../../../assets/theme";
import { t } from "i18next";

export const DialogBox = styled(Box)({
    display: 'flex',
    zIndex: 99,
    padding: '10px',
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont,
});

type DialogProps = {
    okCallback: () => void;
};
const VersionsinfoDialog = ({ okCallback }: DialogProps) => {
    const { data, isLoading } = useGetGlobalInfoQuery(undefined, { refetchOnMountOrArgChange: true });
    const [version, setVersion] = useState('');
    useEffect(() => {
        if (!isLoading) {
            if (data) {
                setVersion(data.version);
            }
        }
    }, [data, isLoading]);

    return (
        <DialogBox >
            <Stack direction="column" spacing={0} alignItems="start" sx={{ padding: '20px', width: '100%', height: '100%' }}>
                <Stack>
                    <Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }} pb="10px">{t('header-drop-down-versioninfo-label')}</Typography>
                </Stack>
                <Stack sx={{ width: '100%' }}>
                    <Divider sx={{ paddingTop: '10px', width: '100%' }} />
                </Stack>
                <Stack pt="20px"><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }}>{'Frontend:'}</Typography></Stack>
                <Stack pb="20px">{VERSION}</Stack>
                <Stack pb="0px"><Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }}>{'Backend:'}</Typography></Stack>
                <Stack pb="30px">{version}</Stack>
                <Stack sx={{ width: '100%', height: '100%' }} alignItems="end" spacing={'20px'} direction="row">
                    <TheraButton fullWidth onClick={okCallback} size="medium" color="success" variant="contained">{'Ok'}</TheraButton>
                </Stack>
            </Stack >
        </DialogBox >
    );
};

export default VersionsinfoDialog;
