import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API_BASE_URL } from "../config/constanst";
import { RootState } from "../store";
import { tokenExpiresIn } from "../store/authSlice";
import { enqueueSnackbar } from "notistack";
import { ErrorMessage } from "../components/CloudMember/DetailsPanel/AddCloudMember";

const defaultResponseHandler = async (res: Response) => {
    const json = await res.json();
    if (res.status === 200) {
        return json;
    }
    if (res.status === 400) {
        if ('message' in json) {
            if (json.message === 'Passwort muss mindestens einen Großbuchstaben, eine Ziffer und ein Sonderzeichen enthalten!') {
                return {
                    message: json.message,
                    type: 'Password error'
                };
            }
        }
        enqueueSnackbar(ErrorMessage(json.message), { key: 'response-error', preventDuplicate: true, anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
        return json;
    }

    return json;
};

export const baseQuery = fetchBaseQuery(
    {
        baseUrl: `${API_BASE_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const tokenType = (getState() as RootState).auth.tokenType;
            const token = (getState() as RootState).auth.accessToken;
            const isAuthorized = tokenExpiresIn(token) > 0;
            if (isAuthorized) {
                headers.set('Authorization', `${tokenType} ${token}`);
            }
            return headers;
        },

        responseHandler(response) {
            return defaultResponseHandler(response);
        },

    }

);
