import { useEffect, useState } from "react";
import { useGetPatientQuery } from "../../../../../api/patientApi";
import { Patient } from "../../../../../types/types";
import { getFullNameOrCode } from "../../../../ResultAssignment/fragments/PinnedPatientList";
import { t } from "i18next";
import { Box } from "@mui/material";

const AssignedListHeader = ({ patientId }: { patientId: string; }) => {
    const { isLoading, data, error } = useGetPatientQuery(patientId);
    const [patient, setPatient] = useState<Patient>();

    useEffect(() => {
        if (!isLoading && !error) {
            if (JSON.stringify(data) !== JSON.stringify(patient)) {
                setPatient(data);
            }

        }
    }, [isLoading, data, error, patient]);

    if (isLoading) {
        return <></>;
    }

    return <Box sx={{ alignContent: 'center' }}>{patient ? t('assigned-sessions-header-title', { name: getFullNameOrCode(patient) }) : '-'}</Box>;
};

export default AssignedListHeader;
