import { Stack, Typography, styled } from "@mui/material";
import { MappedTherapySessionData } from "../../../../types/types";
import LyraIcon from "../../../../icons/LyraIcon";
import { getDurationForSessions, getFieldSumForSessions, getRoundedFieldSumForSessions, millisToHoursString } from "../../../ResultAssignment/fragments/SessionDetailsBox";

const HeaderColumn = styled('div')({
    display: 'inline-flex',
    paddingRight: '10px',
    width: '20%'
});

type Props = {
    assignedSessions: MappedTherapySessionData[];
};

const DeviceTypeResultsHeader = ({ assignedSessions }: Props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: '10px', padding: '20px' }}>

            <HeaderColumn>
                <Typography noWrap fontWeight="bold" textOverflow="ellipsis" >
                    {assignedSessions[0].therapyMethod}
                </Typography>
            </HeaderColumn>

            <HeaderColumn>
                <Typography noWrap textOverflow="ellipsis" >
                    <span style={{ fontWeight: 'bold' }}>{assignedSessions.length}</span> Einheiten
                </Typography>
            </HeaderColumn>

            <HeaderColumn>
                <Typography noWrap textOverflow="ellipsis" ><span style={{ fontWeight: 'bold' }}>{millisToHoursString(getDurationForSessions(assignedSessions))}</span> Stunden</Typography>
            </HeaderColumn>

            <HeaderColumn>
                <Typography noWrap  ><span style={{ fontWeight: 'bold' }}>{getFieldSumForSessions('chartHeader1', assignedSessions, 0)}</span> {assignedSessions[0].chartHeader1Label}</Typography>
            </HeaderColumn>

            <HeaderColumn>
                <Typography noWrap textOverflow="ellipsis" ><span style={{ fontWeight: 'bold' }}>{getRoundedFieldSumForSessions('chartHeader2', assignedSessions)}</span> {assignedSessions[0].chartHeader2Label}</Typography>
            </HeaderColumn>

            <div style={{ marginLeft: '10px', width: '20px' }} >
                <Stack justifyContent="flex-end" direction="row" spacing={5}>
                    <LyraIcon style={{ fontSize: '40px' }} />
                </Stack>
            </div>

        </div >
    );
};

export default DeviceTypeResultsHeader;
