import { DeviceMessage, MappedTherapySessionData } from '../../../types/types';
import { Box, Grid2 as Grid, Popover, Stack, styled, Typography } from '@mui/material';
import InfoIcon from '../../../icons/InfoIcon';
import ClockIcon from '../../../icons/ClockIcon';
import theme, { mediumFont, smallFont } from '../../../assets/theme';
import { useRef, useState } from 'react';
import EditIcon from '../../../icons/EditIcon';
import TrashIcon from '../../../icons/TrashIcon';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';
import LyraIcon from '../../../icons/LyraIcon';
import ThreeDottMenuIcon from '../../../icons/ThreeDottMenuIcon';
import { PopoverEntry } from '../../CloudMember/DetailsPanel/DetailsHeader';
import { t } from 'i18next';
import ShowCommentDialog from '../../Patients/fragments/ShowCommentDialog';
import { parseISO, format } from "date-fns";
import { de } from 'date-fns/locale';
import EditedIconElement from './EditedIconElement';
import { Trans } from 'react-i18next';
import CloseIcon from '../../../icons/CloseIcon';
import SpeechBubble from '../../SpeechBubble/SpeechBubble';

export const NewBox = styled('div')(({ theme }) => ({
    display: 'flex',
    marginLeft: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: 'min-content',
    fontSize: smallFont,
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    borderRadius: '5px',
    backgroundColor: theme.palette.lightgreen.light,
    color: '#209845',
    border: 'solid 2px #209845'

}));

const ArrowBox = styled(Box)({
    textAlign: 'center',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: theme.palette.lightgreen.main,
    alignContent: 'center',
    height: '100% ',
    cursor: 'pointer',
});

const SessionGrid = styled(Grid, {
    shouldForwardProp: (prop) => !['isNew'].includes(prop as string),
})<{ isNew: string; }>(({ theme, isNew }) => ({
    width: '100%',
    fontSize: mediumFont,
    marginBottom: '10px',
    border: isNew === 'true' ? `2px solid ${theme.palette.lightgreen.contrastText}` : `1px solid ${theme.palette.lightgreen.main}`,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',

}));


export enum BoxType {
    ASSIGNED,
    UNASSIGNED
}

export const dateNumbersString = (dateString?: string) => {
    if (dateString) {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy', { locale: getFnsLocale() });
    }
    return '';
};

export const dateString = (dateString?: string) => {
    if (dateString) {
        const date = new Date(dateString);
        return format(date, 'MMMM dd, yyyy', { locale: getFnsLocale() });
    }
    return '';
};

export const isEmpty = (str: string) => {
    if (!str || str.trim().length === 0) {
        return true;
    }
    return false;
};

export const roleName = (role: string) => {
    if (typeof role !== 'string') {
        return '';
    }
    switch (role.toUpperCase()) {
        case 'ROLE_SYSTEM_ADMIN':
            return t('role_system_admin');
        case 'ROLE_MANUFACTURER_ADMIN':
            return t('role_manufacturer_admin');
        case 'ROLE_RETAILER_ADMIN':
            return t('role_retailer_admin');
        case 'ROLE_CLINIC_ADMIN':
            return t('role_clinic_admin');
        case 'ROLE_CLINIC_USER':
            return t('role_clinic_user');
        default:
            return '';
    }
};

export const getFnsLocale = () => {
    return de;
};

export const tleTimeString = (dateString: string) => {
    if (dateString) {
        const date = new Date(dateString);
        return format(date, 'dd.MM.yyyy | HH:mm ') + t('therapy-session-time-label');
    }
    return '';
};

export const iso8601ToDate = (date: string) => {
    return parseISO(date);
};

export const getDurationForSessions = (sessions: MappedTherapySessionData[]) => {
    let duration = 0;
    sessions.forEach(session => {
        duration = duration + getExerciseDuration(session);
    });
    return duration;
};


const getValueAsNumber = (value: any) => {
    if (typeof value === 'string') {
        return parseFloat(value);
    }
    if (typeof value === 'number') {
        return value;
    }
    return 0;
};


export const getFieldSumForSessions = (fieldName: keyof MappedTherapySessionData, sessions: MappedTherapySessionData[], decimal: number) => {
    let sum = 0;
    sessions.forEach(session => {
        sum = sum + getValueAsNumber(session[fieldName]);
    });
    return sum.toFixed(decimal);
};

export const getRoundedFieldSumForSessions = (fieldName: keyof MappedTherapySessionData, sessions: MappedTherapySessionData[]) => {
    let sum = 0;
    sessions.forEach(session => {
        sum = sum + getValueAsNumber(session[fieldName]);
    });
    return Math.round(sum);
};

export const getDecimalValue = (fieldValue: undefined | string | number, decimal: number) => {
    if (!fieldValue) {
        return undefined;
    }
    if (typeof fieldValue === 'string') {
        return parseFloat(fieldValue).toFixed(decimal);
    }
    return fieldValue.toFixed(decimal);
};

export const exerciseDurationMinutes = (session: MappedTherapySessionData) => {
    const start = iso8601ToDate(session.exerciseStartDate);
    const end = iso8601ToDate(session.exerciseEndDate);
    const duration = end.getTime() - start.getTime();
    return (Math.round(duration / 60000));
};

export const millisToHoursString = (millis: number) => {
    const minutes = Math.round(millis / 60000);
    if (minutes >= 60) {
        const hours = `00${Math.floor(minutes / 60)}`;
        const min = `00${minutes - Math.floor(minutes / 60) * 60}`;
        return `${hours.substring(hours.length - 2, hours.length)}:${min.substring(min.length - 2, min.length)}`;
    }
    const min = `00${minutes - Math.floor(minutes / 60) * 60}`;
    return `00:${min.substring(min.length - 2, min.length)}`;
};

export const getExerciseDuration = (session: MappedTherapySessionData) => {
    const duration = session.tlTime ? parseInt(session.tlTime) : 0;
    return duration * 60000;
};

export const getDeviceKey = (session: MappedTherapySessionData | DeviceMessage) => {
    return `${session.type} - ${session.therapyMethod}`;
};
export const getTleExerciseDuration = (session: MappedTherapySessionData) => {
    return <div>{millisToHoursString(getExerciseDuration(session))} h</div>;
};

export const getTleExerciseDurationClock = (session: MappedTherapySessionData) => {
    return <Box style={{ display: 'inline-flex', paddingTop: '10px', alignItems: 'center', fontSize: smallFont, color: theme.palette.active.main }}>
        <ClockIcon sx={{ marginRight: '10px', fontSize: smallFont }} stroke={theme.palette.active.main} />
        <Box fontWeight="bold">{millisToHoursString(getExerciseDuration(session))} h</Box>
        <Box style={{ paddingLeft: '10px', color: theme.palette.primary.main }}>{tleTimeString(session.exerciseStartDate)}</Box>
    </Box>;
};

export const getUnitField = (field: keyof MappedTherapySessionData) => {
    return `${field}Unit` as keyof MappedTherapySessionData;
};

export const getLabelField = (field: keyof MappedTherapySessionData) => {
    return `${field}Label` as keyof MappedTherapySessionData;
};

export const getTleTwoResultsString = (session: MappedTherapySessionData, field1: keyof MappedTherapySessionData, field2: keyof MappedTherapySessionData) => {
    const value1 = session[field1];
    const unit1 = session[getUnitField(field1)];
    const value2 = session[field2];
    const unit2 = session[getUnitField(field2)];
    if (value1 && value2) {
        return `${getValueAsNumber(value1).toFixed(2)} | ${getValueAsNumber(value2).toFixed(2)} ${unit1}`;
    }
    if (value1) {
        return `${getValueAsNumber(value1).toFixed(2)} ${unit1}`;
    }
    if (value2) {
        return `${getValueAsNumber(value2).toFixed(2)} ${unit2}`;
    }
    return undefined;
};


export interface BoxProps {
    session: MappedTherapySessionData;
    boxType: BoxType;
    onArrowClick: (sessionId: string) => void;
    deleteSession: (sessionId: string) => void;
    editSession: (sessionId: string) => void;
    label: string;
}

const SessionDetailsBox = ({ label, editSession, deleteSession, boxType, onArrowClick, session }: BoxProps) => {
    const dialogRef = useRef();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const open = Boolean(anchorEl);
    const [showComment, setShowComment] = useState(false);
    const [infoAnchor, setInfoAnchor] = useState<HTMLElement>();

    const renderArrow = () => {
        return <ArrowBox onClick={() => onArrowClick(session.id)}>{<ArrowLeftIcon style={{ color: theme.palette.lightgreen.contrastText }} />}</ArrowBox>;
    };

    const renderDropDown = () => {
        return (
            <Popover
                open={open}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    borderRadius: '10px',
                    userSelect: 'none'
                }}
            >
                <Stack direction="column" spacing={2} padding="10px">
                    {boxType === BoxType.ASSIGNED && <PopoverEntry onClick={() => { editSession(session.id); setAnchorEl(undefined); }}><EditIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-edit')}</PopoverEntry>}
                    {boxType === BoxType.ASSIGNED && <PopoverEntry onClick={() => { onArrowClick(session.id); setAnchorEl(undefined); }}><TrashIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-unassign')}</PopoverEntry>}
                    {boxType === BoxType.UNASSIGNED && <PopoverEntry onClick={() => { deleteSession(session.id); setAnchorEl(undefined); }}><TrashIcon sx={{ fontSize: smallFont, marginRight: '8px' }} />{t('drop-down-delete')}</PopoverEntry>}
                </Stack>
            </Popover>
        );

    };

    const actionIcon = () => {
        if (boxType === BoxType.ASSIGNED) {
            return <Box ref={dialogRef} onClick={() => setAnchorEl(dialogRef.current)} style={{ cursor: 'pointer' }}><ThreeDottMenuIcon style={{ fontSize: mediumFont }} /></Box>;
        }
        return <Box ref={dialogRef} onClick={() => setAnchorEl(dialogRef.current)} style={{ cursor: 'pointer' }}><ThreeDottMenuIcon style={{ fontSize: mediumFont }} /></Box>;
    };

    const getCommentIcon = () => {
        if (session.comment) {
            return <InfoIcon color="success" onClick={() => setShowComment(true)} style={{ marginLeft: '10px', fontSize: '16pt' }} />;
        }
    };

    const getEditedIcon = () => {
        if (boxType === BoxType.ASSIGNED && !session.isEdited) {
            return <EditedIconElement setAnchorEl={setInfoAnchor} />;
        }
    };

    const getNewBox = () => {
        if (isNew() === 'true') {
            return <NewBox>Neu</NewBox>;
        }
    };

    const isNew = () => {
        const updated = iso8601ToDate(session.exerciseEndDate);
        const diff = Date.now() - updated.getTime();
        if (diff < 1800000) {
            return "true";
        }
        return "false";
    };

    const getTop = () => {
        const t = infoAnchor?.offsetTop ?? 0;
        const h = infoAnchor?.clientHeight ?? 0;
        const top = t - h;
        return top - 75;
    };

    const getLeft = () => {
        const left = infoAnchor?.offsetLeft ?? 0;
        return left - 200;
    };

    return (<SessionGrid isNew={isNew()} container>
        {boxType === BoxType.UNASSIGNED && <Grid size={1}>{renderArrow()}</Grid>}
        <Grid container size={boxType === BoxType.ASSIGNED ? 10 : 9} sx={{ padding: '20px 0px 20px 20px' }}>
            <Grid container direction="column" >
                <Stack direction="row">
                    <Box sx={{ fontWeight: 'bold', display: 'flex' }}>
                        <LyraIcon style={{ fontSize: '44px' }} />
                    </Box>
                    <Stack direction="column">
                        <Stack direction="row">
                            <Typography fontWeight="bold">{label}</Typography>
                            {getCommentIcon()}{getEditedIcon()}{getNewBox()}
                        </Stack>
                        <Typography fontWeight="normal">{session.therapyMethod}</Typography>
                    </Stack>
                </Stack>

                <Grid>{getTleExerciseDurationClock(session)}</Grid>
                <Grid>{session[getLabelField('tlResult2')]}: {session.tlResult2} {session.tlResult2Unit}</Grid>
                <Grid>{session[getLabelField('tlResult3')]}: {getTleTwoResultsString(session, 'tlResult3', 'tlResult4')}</Grid>
            </Grid>
        </Grid>
        <Grid size={2} sx={{ display: 'flex', flexDirection: 'column', paddingTop: '10px', paddingRight: '20px', overflow: 'visible', textAlign: 'right' }}>
            <Box style={{ display: 'inline', paddingTop: '10px', textAlign: 'right' }}>
                {actionIcon()}
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%', fontWeight: 'bold' }}>
                <Box style={{ display: 'inline-flex', marginTop: 'auto', fontWeight: 'bold', }}>
                    {(session.tlResult1)}
                </Box>
                <Box style={{ display: 'inline-flex', fontWeight: 'bold', paddingBottom: '20px' }}>
                    {session.tlResult1Label}
                </Box>
            </Box>
        </Grid >
        {renderDropDown()}
        {showComment && <ShowCommentDialog session={session} setShowComment={setShowComment} />}
        {infoAnchor &&
            <SpeechBubble position="right" ignoreFirstClick={false} setInfoAnchor={setInfoAnchor} top={getTop()} left={getLeft()} child={
                <Stack direction="row" sx={{ margin: '15px' }}>
                    <Box sx={{ paddingTop: '5px', paddingRight: '20px' }}><Trans i18nKey={'session-not-edited-info-message'} /></Box>
                    <Box textAlign={"end"} sx={{ cursor: 'pointer' }} onClick={() => setInfoAnchor(undefined)}>
                        <CloseIcon sx={{ fontSize: smallFont }} />
                    </Box>
                </Stack>
            } />

        }
    </SessionGrid >);
};

export default SessionDetailsBox;
