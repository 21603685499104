import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

interface UiState {
    patientId?: string;
    accordionSessionId?: string;
}

const initialState: UiState = {
    patientId: undefined
};

export const uiState = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setAccordionSessionId: (state, { payload }) => {
            state.accordionSessionId = payload;
        },
        setCurrentPatient: (state, { payload }) => {
            state.patientId = payload;
        },
        resetState: (state) => {
            state.patientId = undefined;
        },
    },
});

export const { setAccordionSessionId, setCurrentPatient, resetState } = uiState.actions;
export const selectCurrentPatientId = (state: RootState) => state.ui.patientId;
export const selectAccordionSessionId = (state: RootState) => state.ui.accordionSessionId;


