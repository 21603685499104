import { useAppSelector } from "../../store";
import { selectIsClinicAdmin, selectIsClinicUser, selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../store/authSlice";
import NavigationButton from "./NavigationButton";
import PatientsIcon from "../../icons/PatientsIcon";
import ResultAssignmentIcon from "../../icons/ResultAssignmentIcon";
import DeviceIcon from "../../icons/DeviceIcon";
import ClinicIcon from "../../icons/ClinicIcon";
import RetailerIcon from "../../icons/RetailerIcon";
import ManufacturerIcon from "../../icons/ManufacturerIcon";
import UserIcon from "../../icons/UserIcon";
import ResultAssignmentNavigationButton from "./ResultAssignmentNavigationButton";
import { t } from "i18next";

export const MainNavigation = () => {
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const isClinicUser = useAppSelector(selectIsClinicUser);

    const getClinicUser = () => {
        return (
            <>
                <NavigationButton to="/patients" label={t('navigation-button-patients')} Icon={PatientsIcon} />
                <ResultAssignmentNavigationButton to="/result-assignment" label={t('navigation-button-result-assignment')} Icon={ResultAssignmentIcon} />
            </>
        );
    };
    const getClinicAdmin = () => {
        return (
            <>
                <NavigationButton to="/user" label={t('navigation-button-user')} Icon={UserIcon} />
                <NavigationButton to="/devices" label={t('navigation-button-device')} Icon={DeviceIcon} />
                <NavigationButton to="/retailer" label={t('navigation-button-retailer')} Icon={RetailerIcon} />
            </>
        );
    };
    const getRetailerAdmin = () => {
        return (
            <>
                <NavigationButton to="/devices" label={t('navigation-button-device')} Icon={DeviceIcon} />
                <NavigationButton to="/device-logs" label={t('navigation-button-device-logs')} Icon={ResultAssignmentIcon} />
                <NavigationButton to="/clinic" label={t('navigation-button-clinic')} Icon={ClinicIcon} />
                <NavigationButton to="/manufacturer" label={t('navigation-button-manufacturer')} Icon={ManufacturerIcon} />
                <NavigationButton to="/user" label={t('navigation-button-user')} Icon={UserIcon} />
            </>
        );
    };
    const getManufacturerAdmin = () => {
        return (
            <>
                <NavigationButton to="/devices" label={t('navigation-button-device')} Icon={DeviceIcon} />
                <NavigationButton to="/device-logs" label={t('navigation-button-device-logs')} Icon={ResultAssignmentIcon} />
                <NavigationButton to="/retailer" label={t('navigation-button-retailer')} Icon={RetailerIcon} />
                <NavigationButton to="/user" label={t('navigation-button-user')} Icon={UserIcon} />
            </>
        );
    };

    const getSystemAdmin = () => {
        return (
            <>
                <NavigationButton to="/user" label={t('navigation-button-user')} Icon={UserIcon} />
                <NavigationButton to="/locked-accounts" label={t('navigation-button-locked-accounts')} Icon={UserIcon} />
                <NavigationButton to="/manufacturer" label={t('navigation-button-manufacturer')} Icon={ManufacturerIcon} />
                <NavigationButton to="/retailer" label={t('navigation-button-retailer')} Icon={RetailerIcon} />
                <NavigationButton to="/clinic" label={t('navigation-button-clinic')} Icon={ClinicIcon} />
                <NavigationButton to="/devices" label={t('navigation-button-device')} Icon={DeviceIcon} />
                <NavigationButton to="/device-logs" label={t('navigation-button-device-logs')} Icon={ResultAssignmentIcon} />
            </>
        );
    };
    return (
        <nav style={{ height: '70px', lineHeight: '70px' }}>
            {isClinicUser && getClinicUser()}
            {isClinicAdmin && getClinicAdmin()}
            {isRetailerAdmin && getRetailerAdmin()}
            {isManufacturerAdmin && getManufacturerAdmin()}
            {isSystemAdmin && getSystemAdmin()}
        </nav>


    );
};
