import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { selectIsManufacturerAdmin, selectIsRetailerAdmin } from "../../../../store/authSlice";
import TheraButton from "../../../TheraButtons/TheraButton";
import theme, { largeFont } from "../../../../assets/theme";
import useGetMemberNames from "../../../../hooks/useGetMemberNames";

const DialogBox = styled(Box)({
    width: '600px',
    display: 'flex',
    zIndex: 99,
    padding: '10px',
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont
});

type DialogProps = {
    addCallback: (token?: string) => void;
    acceptCallback: (token?: string) => void;
    cancelCallback: () => void;
};
const ChooseAddActionDialog = ({ cancelCallback, addCallback, acceptCallback }: DialogProps) => {
    const { acceptMemberName } = useGetMemberNames();
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);

    const getDialogText = () => {
        if (isManufacturerAdmin) {
            return t('add-retailer-dialog-text');
        }
        if (isRetailerAdmin) {
            return t('add-clinic-dialog-text');
        }
    };

    const getCreateButtonLabel = () => {
        if (isManufacturerAdmin) {
            return t('retailer-create-button');
        }
        if (isRetailerAdmin) {
            return t('clinic-create-button');
        }
    };
    return (
        <DialogBox>
            <Stack direction="column" spacing={0} alignItems="start" sx={{ padding: '20px', width: '100%', height: '100%' }}>
                <Stack direction="row" width="100%" >
                    <Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }} width="100%"><Trans i18nKey={'add-member-dialog-title'} values={{ name: acceptMemberName }} /></Typography>
                    <Typography sx={{ cursor: 'pointer' }} onClick={cancelCallback}><CloseIcon stroke={theme.palette.linkgray.main} /></Typography>
                </Stack>
                <Stack sx={{ width: '100%' }}>
                    <Divider sx={{ paddingTop: '20px', width: '100%' }} />
                </Stack>
                <Stack pt="20px">
                    <Trans>{getDialogText()}</Trans>
                    <p />
                </Stack>
                <Stack sx={{ paddingTop: '60px', width: '100%', height: '100%' }} alignItems="end" spacing={'20px'} direction="column">
                    <TheraButton fullWidth onClick={() => addCallback()} size="medium" color="success" variant="contained">{getCreateButtonLabel()}</TheraButton>
                    <TheraButton fullWidth onClick={() => acceptCallback()} size="medium" color="success" variant="contained">{t('accept-invitation-button')}</TheraButton>
                </Stack>
            </Stack >
        </DialogBox >
    );
};

export default ChooseAddActionDialog;
