import { Box, Dialog, Divider, Grid, Typography, styled } from "@mui/material";
import { ReactNode } from "react";
import TheraButton from "../../TheraButtons/TheraButton";
import theme, { largeFont } from "../../../assets/theme";

const DialogBox = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '250px',
    width: '400px',
    zIndex: 99,
    border: theme.palette.primary.light,
    backgroundColor: '#FFF',
    boxShadow: '1px 3px 6px #00000029',
    borderRadius: '10px',
    fontSize: largeFont,
});

type Props = {
    isVisible: boolean;
    title: string;
    message: ReactNode;
    okCallback: () => void;
    cancelCallback: () => void;
};
const ConfirmDialog = ({ title, okCallback, cancelCallback, message, isVisible }: Props) => {
    return (isVisible ? (
        <Dialog PaperProps={{ sx: { borderRadius: '5px' } }} open={isVisible}>
            <DialogBox>
                <Box sx={{ textAlign: 'center', padding: '20px' }}>
                    <Typography sx={{ paddingBottom: '10px', fontSize: largeFont, fontWeight: 'bold' }} >{title}</Typography>
                    <Divider sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 'auto' }}>
                    {message}
                </Box>
                <Box sx={{ display: 'flex', padding: '20px' }}>
                    <Box sx={{ paddingRight: '10px', width: '50%' }}>
                        <TheraButton fullWidth onClick={okCallback} size="medium" color="success" variant="contained">{'Ja'}</TheraButton>
                    </Box>
                    <Box sx={{ paddingLeft: '10px', width: '50%' }}>
                        <TheraButton fullWidth onClick={cancelCallback} size="medium" color="lightgreen" variant="contained">{'Nein'}</TheraButton>
                    </Box>
                </Box>
            </DialogBox>
        </Dialog >
    ) : <></>);
};

export default ConfirmDialog;
