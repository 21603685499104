import { memo, useEffect, useState } from 'react';
import ManufacturerDetails from './fragments/ManufacturerDetails';
import TwoColumns from '../PageTemplates/TwoColumns';
import EditManufacturer from './fragments/EditManufacturer';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIsSystemaAdmin, selectIsRetailerAdmin } from '../../store/authSlice';
import ListPanelButtonBar from '../CloudMember/MemberListPanels/ListPanelButtonBar';
import { Pages } from '../CloudMember/MemberListPanels/listPanelGlobals';
import AddCloudMember from '../CloudMember/DetailsPanel/AddCloudMember';
import CloudMemberList from '../CloudMember/MemberListPanels/CloudMemberList';
import { t } from 'i18next';
import { resetFilter } from '../../store/filterState';
import { useAddManufacturerMutation, useGetManufacturerListQuery } from '../../api/manufacturerApi';
import { resetListState } from '../../store/listStateSlice';

const MemoCloudMemberList = memo(CloudMemberList);

const Manufacturer = () => {
    const [manufacturerId, setManufacturerId] = useState("");
    const [page, setPage] = useState(Pages.DETAILS);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const [addManufacturer] = useAddManufacturerMutation();
    const { isLoading, data } = useGetManufacturerListQuery(undefined, { refetchOnMountOrArgChange: true });
    const [scrollPos, setScrollPos] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(resetListState());
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            if (manufacturerId && manufacturerId !== "") {
                const selectedMember = data.find((entry) => entry.id === manufacturerId);
                if (!selectedMember) {
                    showDetails(data[0].id, scrollPos);
                }
            } else {
                showDetails(data[0].id, scrollPos);
            }
        }
    }, [isLoading, data, manufacturerId, scrollPos, dispatch]);


    if (isLoading) {
        return <div>loading...</div>;
    }

    const showDetails = (id: string, scrollPos: number) => {
        setScrollPos(scrollPos);
        setManufacturerId(() => id);
        setPage(Pages.DETAILS);
    };

    const addEntry = () => {
        setPage(Pages.ADD);
    };

    const getRightComponent = () => {
        switch (page) {
            case Pages.ADD:
                return (<AddCloudMember addMember={addManufacturer} setPage={setPage} />);
            case Pages.EDIT:
                return (<EditManufacturer setPage={setPage} manufacturerId={manufacturerId} />);
            case Pages.DETAILS:
                return (<>{manufacturerId && <ManufacturerDetails setPage={setPage} manufacturerId={manufacturerId} />}</>);
            default:
                if (manufacturerId) {
                    return (<ManufacturerDetails setPage={setPage} manufacturerId={manufacturerId} />);
                }
                return (<div style={{ padding: '20px' }}>Default Detailseite</div>);
        }

    };

    return (
        <TwoColumns leftComponent={
            <>
                <ListPanelButtonBar
                    acceptText={t('accept-retailer-dialog-text')}
                    searchFieldPlaceholder={t('manufacturer-searchfield-placeholder')}
                    addEntryLabel={isRetailerAdmin ? t('manufacturer-invite-button') : t('manufacturer-add-button')}
                    showAddButton={isSystemAdmin}
                    showAcceptButton={false}
                    showInviteButton={isRetailerAdmin}
                    addNewEntry={addEntry} />
                <MemoCloudMemberList scrollPos={scrollPos} data={data} selectedId={manufacturerId} showDetails={showDetails} />
            </>}
            rightComponent={getRightComponent()} />
    );

};

export default Manufacturer;
