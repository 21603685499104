import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

interface DateFilterState {
    dateRange?: [number, number];
}

const initialState: DateFilterState = {
    dateRange: undefined
};

export const dateFilterState = createSlice({
    name: 'datefilter',
    initialState,
    reducers: {
        setDateFilter: (state, { payload }) => {
            state.dateRange = [payload[0], payload[1]];
        },
        clearDateFilterState: (state) => {
            state.dateRange = undefined;
        },
    },
});

export const { setDateFilter, clearDateFilterState } = dateFilterState.actions;
export const selectDateFilter = (state: RootState) => state.datefilter.dateRange;


