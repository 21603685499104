import { PieChart } from "@mui/x-charts";
import { largeFont, pieChartColors } from "../../../../assets/theme";
import { Box, Grid, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MappedTherapySessionData } from "../../../../types/types";
import ClinicButton from "../../../TheraButtons/ClinicButton";
import { getDurationForSessions, millisToHoursString } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import { getSessionsForType, getUniqueDeviceKeys } from "../../../../hooks/useFetchAssignedSessions";

const ColorBox = styled('div', {
    shouldForwardProp: (prop) => !['color'].includes(prop as string),
})<{ color: string; }>(({ color }) => ({
    display: 'inline-flex',
    lineHeight: '18px',
    width: largeFont,
    minWidth: largeFont,
    height: largeFont,
    minHeight: largeFont,
    backgroundColor: color,
    borderRadius: '5px',
    margin: '3pt',
    marginRight: '10px'
}));

type ChartData = {
    id: number;
    method: string;
    value: number;
    color: string;
};

type Props = {
    assignedSessions: MappedTherapySessionData[];
};

const SessionsPieChart = ({ assignedSessions }: Props) => {
    const boxRef = useRef<HTMLDivElement>();
    const [size, setSize] = useState<number[]>([0, 0]);
    const [activeState, setActiveState] = useState(0);
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const unique = getUniqueDeviceKeys(assignedSessions);
    const [sessions, setSessions] = useState<MappedTherapySessionData[]>(assignedSessions);

    useEffect(() => {
        const flag = JSON.stringify(assignedSessions) !== JSON.stringify(sessions);
        if (flag) {
            setSessions(() => assignedSessions);
        }
    }, [sessions, assignedSessions]);

    useEffect(() => {
        let result: ChartData[] = [];
        unique.forEach((deviceKey, index) => {
            const sessionsForType = getSessionsForType(sessions, deviceKey);
            if (sessionsForType) {
                const duration = getDurationForSessions(sessionsForType);
                result.push(
                    {
                        id: index,
                        method: sessionsForType[0].therapyMethod,
                        value: activeState === 0 ? duration : sessionsForType.length,
                        color: pieChartColors[index % 10]
                    }
                );
            }
        });
        if (JSON.stringify(result) !== JSON.stringify(chartData)) {
            setChartData(() => result);
        }
    }, [chartData, unique, sessions, activeState]);

    useEffect(() => {
        if (boxRef.current) {
            const width = boxRef.current.clientWidth;
            const height = boxRef.current.clientHeight;
            setSize(() => [width, height]);
        }
    }, [boxRef]);

    useEffect(() => {
        if (!boxRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (boxRef.current) {
                const width = boxRef.current.clientWidth;
                const height = boxRef.current.clientHeight;
                setSize(() => [width, height]);
            }

        });
        resizeObserver.observe(boxRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    const calculatePercent = (data: ChartData) => {
        let sum = 0;
        chartData.forEach(entry => {
            sum = sum + entry.value;
        });

        return Math.round((data.value / sum) * 100);
    };

    const getRadius = () => {
        if (size[0] > size[1]) {
            return size[1] - 40;
        };
        return size[0] - 40;
    };

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={6}>
                <Box ref={boxRef} style={{ display: 'block', height: '100%' }}>
                    <PieChart
                        series={[
                            {
                                data: chartData,
                                innerRadius: getRadius() * 0.17,
                                outerRadius: getRadius() * 0.5,
                                paddingAngle: 3,
                                cornerRadius: 5,
                                cx: getRadius() * 0.5 + 10,
                                cy: getRadius() * 0.5 + 10,
                            },
                        ]}
                        tooltip={{ trigger: 'none' }}
                        height={getRadius() + 20}
                        width={getRadius() + 20}
                    />
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 20px 0px 0px', width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                        <table style={{ width: '100%', overflow: 'auto' }}>
                            <thead style={{ position: 'sticky', top: '0px', background: 'white' }}>
                                <tr>
                                    <th align="left">Trainingsart</th>
                                    <th align="left">{activeState === 0 ? 'Dauer' : 'Einheiten'}</th>
                                    <th align="left">%</th>
                                </tr>
                                <tr >
                                    <th colSpan={3}><hr /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    chartData.map((data, index) => {
                                        return (<tr key={data.id} style={{ height: '32px' }}>
                                            <td><ColorBox color={data.color}>&nbsp;</ColorBox>{data.method}</td>
                                            <td>{activeState === 0 ? millisToHoursString(data.value) : data.value}</td>
                                            <td>{`${calculatePercent(data)}%`}</td>
                                        </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
                        <ClinicButton position="left" active={activeState === 0} onClick={(e) => { setActiveState(0); }}>Dauer</ClinicButton>
                        <ClinicButton position="right" active={activeState === 1} onClick={(e) => { setActiveState(1); }}>Einheiten</ClinicButton>
                    </div>
                </div>
            </Grid>
        </Grid >
    );
};

export default SessionsPieChart;
