import { useEffect, useState } from "react";
import { DeviceMessage } from "../types/types";
import { useGetDeviceListQuery } from "../api/deviceApi";

export const getDeviceName = (devices: DeviceMessage[], serial: string) => {
    if (devices && devices.length > 0) {
        const device = devices.find(device => device.serial === serial);
        if (device) {
            return device.name;
        }
    }
    return undefined;
};

const useFetchDevices = () => {
    const { data, isLoading, error } = useGetDeviceListQuery(undefined, { pollingInterval: 60000 });
    const [devices, setDevices] = useState<DeviceMessage[]>();
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);
    const [devicesError, setDevicesError] = useState<string | null>(null);

    useEffect(() => {
        setIsDevicesLoading(isLoading);
        if (error) {
            setIsDevicesLoading(false);
            setDevicesError(JSON.stringify(error));
        } else if (!isLoading && data) {
            if (JSON.stringify(data) !== JSON.stringify(devices)) {
                setDevices(data);
                setDevicesError(null);
            }
            setIsDevicesLoading(false);
        }
    }, [isLoading, data, error, devices]);

    return { isDevicesLoading, devices, devicesError };

};

export default useFetchDevices;
