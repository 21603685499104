import { GlobalInfo } from '../types/types';
import { emptyBaseApi } from './emptyBaseApi';

export const globalInfoApi = emptyBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGlobalInfo: builder.query<GlobalInfo, void>({
            query: () => ({
                url: 'globals/info',
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useGetGlobalInfoQuery } = globalInfoApi;
