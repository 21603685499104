import { styled } from "@mui/material";
import { useAppSelector } from "../../../../store";
import { selectDeviceFilter } from "../../../../store/filterState";
import { tinyFont } from "../../../../assets/theme";


export const NumberElement = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: tinyFont,
    height: '22px',
    width: '22px',
    marginRight: '10px',
    color: theme.palette.background.default,
    backgroundColor: theme.palette.darkgreen.main,
    borderRadius: '11px',

}));

const FilterCount = () => {
    const filteredDevices = useAppSelector(selectDeviceFilter);
    const count = filteredDevices.filter(serial => serial !== 'all').length;
    return <>{count > 0 ? < NumberElement > {count.toString()}</NumberElement > : undefined}</>;
};

export default FilterCount;
