import { SetStateAction, useEffect, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Select, Divider, Grid2 as Grid, Stack, MenuItem, Checkbox, Typography, Box } from "@mui/material";
import { t } from "i18next";
import { Pages } from "../../../CloudMember/MemberListPanels/listPanelGlobals";
import { useUpdateLyraDeviceMutation } from "../../../../api/deviceApi";
import { CloudMember, DeviceMessage } from "../../../../types/types";
import { useAppSelector } from "../../../../store";
import { selectIsClinicAdmin, selectIsManufacturerAdmin, selectIsSystemaAdmin, selectIsRetailerAdmin } from "../../../../store/authSlice";
import { useGetAllRetailerMutation } from "../../../../api/retailerApi";
import { useGetAllClinicsMutation } from "../../../../api/clinicApi";
import DeviceAvatar from "../../../Avatar/DeviceAvatar";
import StyledMultilineField from "../../../StyledTextField/StyledMultilineField";
import StyledTextField from "../../../StyledTextField/StyledTextField";
import StyledText from "../../../StyledTextField/StyledText";
import StyledLabel from "../../../StyledTextField/StyledLabel";
import FooterText from "../../../StyledTextField/FooterText";
import TheraButton from "../../../TheraButtons/TheraButton";
import { largeFont } from "../../../../assets/theme";
import { dateNumbersString, dateString, isEmpty } from "../../../ResultAssignment/fragments/SessionDetailsBox";
import { DatePicker } from "rsuite";

const ErrorMessage = (message: any) => {
    return (<div style={{ padding: '5px' }}>{message}</div>);
};

type DetailsProps = {
    setPage: (value: SetStateAction<Pages>) => void;
    data: DeviceMessage;
};

const EditLyraDevice = ({ data, setPage }: DetailsProps) => {
    const [updateDevice] = useUpdateLyraDeviceMutation();
    const isClinicAdmin = useAppSelector(selectIsClinicAdmin);
    const isRetailerAdmin = useAppSelector(selectIsRetailerAdmin);
    const isManufacturerAdmin = useAppSelector(selectIsManufacturerAdmin);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);
    const [licenceDate, setLicenceDate] = useState(data?.licenceDate);
    const token = useRef<HTMLInputElement>();
    const [debugMode, setDebugMode] = useState<boolean>(data?.debugMode || false);
    const name = useRef<HTMLInputElement>();
    const comment = useRef<HTMLInputElement>();

    const [retailerId, setRetailerId] = useState(data?.retailerId);
    const [retailerList, setRetailerList] = useState<CloudMember[]>();
    const [getRetailerList] = useGetAllRetailerMutation();


    const [clinicId, setClinicId] = useState(data?.clinicId);
    const [clinicList, setClinicList] = useState<CloudMember[]>();
    const [getClinicList] = useGetAllClinicsMutation();

    useEffect(() => {
        if (isManufacturerAdmin) {
            getRetailerList().then((result) => {
                if ('data' in result) {
                    setRetailerList(() => result.data);
                }
            });
        }
    }, [isManufacturerAdmin, getRetailerList]);


    useEffect(() => {
        if (isRetailerAdmin) {
            getClinicList().then((result) => {
                if ('data' in result) {
                    setClinicList(() => result.data);
                }
            });
        }
    }, [isRetailerAdmin, getClinicList]);


    if (!data) {
        return <div>Loading data...</div>;
    }

    const renderRetailerDropdown = () => {
        return (<Select sx={{ height: '50px', fontSize: largeFont }} id="retailer-select"
            value={retailerId} onChange={(e) => { setRetailerId(e.target.value); }}>
            {retailerList?.map((member: CloudMember) =>
                <MenuItem sx={{ fontSize: largeFont }} key={member.id} value={member.id}>{member.name}</MenuItem>
            )}
        </Select >);
    };


    const renderClinicDropdown = () => {
        return (<Select sx={{ height: '50px', fontSize: largeFont }} id="clinic-select"
            value={clinicId} onChange={(e) => { setClinicId(e.target.value); }}>
            {clinicList?.map((member: CloudMember) =>
                <MenuItem sx={{ fontSize: largeFont }} key={member.id} value={member.id}>{member.name}</MenuItem>
            )}
        </Select >);
    };


    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        if (name.current && isEmpty(name.current.value)) {
            enqueueSnackbar(ErrorMessage(t('cloud-member-form-no-name-error')), { anchorOrigin: { horizontal: 'left', vertical: 'top' }, variant: 'error' });
            return;
        }
        const params = {
            id: data?.id ?? '',
            name: name.current?.value ?? '',
            therapyMethod: data.therapyMethod,
            comment: comment.current?.value ?? '',
            serial: data?.serial ?? '',
            manufacturerId: data?.manufacturerId ?? '',
            retailerId: retailerId ?? '',
            clinicId: clinicId ?? '',
            token: token.current?.value ?? data.token ?? '',
            licenceDate,
            debugMode
        };

        updateDevice(params);
        setPage(Pages.DETAILS);
    };

    const getManufacturerName = () => {
        return data.manufacturerName ?? '-';
    };

    const getRetailerName = () => {
        return data.retailerName ?? '-';
    };

    const getClinicName = () => {
        if (isSystemAdmin || isRetailerAdmin || isClinicAdmin) {
            return data.clinicName ?? '-';
        }
        return '-';
    };

    return (
        <Stack direction="column" sx={{ height: 'calc(100% - 20px)', overflow: 'auto' }}>
            <Stack sx={{ display: 'flex', padding: '20px 15px 20px 20px', width: '100%', }} alignItems="center" direction="row">
                <div style={{ marginRight: '20px' }}>
                    <DeviceAvatar size="large" typ={data?.type} />
                </div>

                <Stack direction="column" sx={{ width: '100%' }}>
                    <Typography sx={{ fontSize: largeFont, fontWeight: 'bold' }}>{data?.name}</Typography>
                    <Typography sx={{ fontSize: largeFont }}>{data.therapyMethod}</Typography>
                </Stack>
            </Stack >

            <form onSubmit={handleSubmit} style={{ paddingLeft: '20px', height: '100%' }}>
                <Stack direction="column" sx={{ paddingRight: '15px', height: '100%' }} >
                    <Divider />
                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('cloud-member-form-comment-label')}</StyledLabel>
                        {isClinicAdmin || isRetailerAdmin ? (
                            <StyledText>{data.comment}</StyledText>
                        ) : (
                            <StyledMultilineField
                                variant="outlined"
                                color="primary"
                                multiline
                                rows={3}
                                inputRef={comment}
                                defaultValue={data?.comment}
                            />)}
                    </Stack>

                    <Divider sx={{ height: '20px' }} />

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                        <StyledLabel>{t('device-form-type-label')}</StyledLabel>
                        <StyledText>{data?.type}</StyledText>
                    </Stack>

                    {isSystemAdmin || isManufacturerAdmin || isClinicAdmin ? (
                        <Stack sx={{ display: 'flex', paddingTop: '10px', width: '100%', }} direction="column">
                            <StyledLabel >{t('device-form-name-label')}</StyledLabel>
                            <StyledTextField
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={name}
                                defaultValue={data?.name}
                                slotProps={{
                                    htmlInput: {
                                        "data-testid": "name"
                                    }
                                }}
                            />
                        </Stack>) : <></>}

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel >{t('device-form-serial-label')}</StyledLabel>
                        <StyledText>{data?.serial}</StyledText>
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('device-form-manufacturer-label')}</StyledLabel>
                        <StyledText>{getManufacturerName()}</StyledText>
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('device-form-retailer-label')}</StyledLabel>
                        {isManufacturerAdmin ? renderRetailerDropdown() : <StyledText>{getRetailerName()}</StyledText>}
                    </Stack>


                    <Stack sx={{ display: 'flex', paddingTop: '20px', width: '100%', }} direction="column">
                        <StyledLabel>{t('device-form-clinic-label')}</StyledLabel>
                        {isRetailerAdmin ? renderClinicDropdown() : <StyledText>{getClinicName()}</StyledText>}
                    </Stack>


                    <Stack sx={{ paddingTop: '20px', display: 'flex', width: '100%', }} direction="column">
                        <StyledLabel>{t('device-form-token-label')}</StyledLabel>
                        {isClinicAdmin || isRetailerAdmin ? (
                            <>{data.token}</>
                        ) : (
                            <StyledTextField
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                                type="text"
                                inputRef={token}
                                defaultValue={data?.token}
                            />)}
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                        <StyledLabel pl={'0px'}>{t('device-form-debug-label')}</StyledLabel>
                        <Box><StyledLabel><Checkbox onChange={(event, checked) => setDebugMode(checked)} checked={debugMode} />ein</StyledLabel></Box>
                    </Stack>

                    <Stack sx={{ display: 'flex', paddingTop: '20px', paddingBottom: '10px', width: '100%', }} direction="column">
                        <StyledLabel pl={'0px'}>{t('device-form-licence-label')}</StyledLabel>
                        {!isManufacturerAdmin ? (
                            <>{dateNumbersString(data.licenceDate)}</>
                        ) : (
                            <DatePicker
                                placement="topEnd"
                                onChange={(date) => setLicenceDate(() => date?.toISOString())}
                                size="lg"
                                editable={false}
                                format="dd.MM.yyyy"
                                placeholder={t('device-form-pick-date-label')}
                                defaultValue={licenceDate ? new Date(Date.parse(licenceDate)) : undefined}

                            />
                        )}

                    </Stack>

                    <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '20px' }} >
                        <Stack spacing={'20px'} direction="row">
                            <TheraButton sx={{ width: '50%' }} onClick={() => setPage(Pages.DETAILS)} size="medium" color="lightgreen" variant="contained">{t('cloud-member-form-cancel-button')}</TheraButton>
                            <TheraButton sx={{ width: '50%' }} type="submit" size="medium" color="success" variant="contained">{t('cloud-member-form-save-button')}</TheraButton>
                        </Stack>
                    </Stack>

                    <Stack style={{ justifyContent: 'end', height: '100%', paddingTop: '15px' }} >
                        <Divider sx={{ marginBottom: '15px' }} />
                        <Grid container>
                            <Grid size={6}>
                                <FooterText fontWeight="bold">{t('cloud-member-form-updated-label')}</FooterText>
                            </Grid>
                            <Grid size={6} sx={{ textAlign: 'right' }}>
                                <FooterText fontWeight="bold">{t('cloud-member-form-created-label')}</FooterText>
                            </Grid>
                            <Grid size={6}>
                                <FooterText>{dateString(data?.lastUpdated || data?.created)}</FooterText>
                            </Grid>
                            <Grid size={6} sx={{ textAlign: 'right' }}>
                                <FooterText>{dateString(data?.created)}</FooterText>
                            </Grid>
                        </Grid>
                    </Stack>

                </Stack >
            </form >
        </Stack >
    );
};

export default EditLyraDevice;
