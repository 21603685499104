import { useEffect, useRef } from "react";
import { Grid2 as Grid, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CloudMember, DeviceMessage, Patient, UserMessage } from "../../../types/types";
import Avatar from "../../Avatar/Avatar";
import TwoArrowsUpIcon from "../../../icons/TwoArrowsUpIcon";
import TwoArrowsDownIcon from "../../../icons/TwoArrowsDownIcon";
import { t } from "i18next";
import theme, { largeFont, mediumFont, tinyFont } from "../../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectActiveFilter, selectDeletedFilter, selectInactiveFilter } from "../../../store/filterState";
import { Active, Inactive } from "./FilterButton";
import { selectListField, selectListFilter, selectListOrder, setListOrderState } from "../../../store/listStateSlice";
import { selectIsSystemaAdmin } from "../../../store/authSlice";
import TrashIcon from "../../../icons/TrashIcon";
import StyledTableRow from "../../StyledTable/StyledTableRow";
import { ColumnLabel, DataTableCell, HeaderTableCell } from "../../StyledTable/StyledTableElements";

const sortData = (sortField: keyof CloudMember, sortOrder: string, data?: CloudMember[]) => {
    if (!data) {
        return [];
    }
    return data.slice().sort((a, b) => {
        if (a[sortField] > b[sortField]) {
            return sortOrder === "up" ? 1 : -1;
        }
        if (a[sortField] < b[sortField]) {
            return sortOrder === "up" ? -1 : 1;
        }
        return 0;
    });
};

export const getActiveStatus = (member: CloudMember | DeviceMessage | UserMessage | Patient) => {
    if (member.active) {
        return <Active>{t('active-label')}</Active>;
    }

    return <Inactive>{t('inactive-label')}</Inactive>;
};

type ListProps = {
    showDetails: (id: string, scrollPos: number) => void;
    selectedId?: string;
    data?: CloudMember[];
    scrollPos: number;
};

const CloudMemberList = ({ scrollPos, data, selectedId, showDetails }: ListProps) => {
    const dispatch = useAppDispatch();
    const sortOrder = useAppSelector(selectListOrder);
    const sortField = useAppSelector(selectListField) as keyof CloudMember;
    const filter = useAppSelector(selectListFilter);
    const ref = useRef<HTMLTableElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const active = useAppSelector(selectActiveFilter);
    const inactive = useAppSelector(selectInactiveFilter);
    const deleted = useAppSelector(selectDeletedFilter);
    const isSystemAdmin = useAppSelector(selectIsSystemaAdmin);

    useEffect(() => {
        if (ref.current?.parentElement) {
            ref.current.parentElement.scrollTo({ top: scrollPos });
        }
    }, [ref, scrollPos]);


    const clickInRow = ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
        if (selectedId !== id) {
            if (tableRef.current) {
                showDetails(id, tableRef.current?.scrollTop);
            } else {
                showDetails(id, 0);
            }
        }
    });

    const isInFilter = (member: CloudMember) => {
        if (member.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || member.contact.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
            return true;
        }
        return false;
    };

    const getFilteredList = (list?: CloudMember[]) => {
        if (list) {
            const filteredList = list.filter((entry) => isInFilter(entry))
                .filter((entry) => (!active && !inactive) || (active && entry.active) || (inactive && !entry.active))
                .filter((entry) => !deleted || (deleted && entry.deleted));

            const mappedList = filteredList.map((member: CloudMember) => {
                return (
                    <StyledTableRow
                        selected={selectedId === member.id}
                        key={member.id} sx={{ height: '80px', cursor: 'pointer' }} hover onClick={(event) => clickInRow(event, member.id)}
                    >
                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>

                        <DataTableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}><Avatar name={member.name} size="small" />
                                <Typography fontSize={largeFont} pl={'10px'}>{member.name}</Typography></div>
                        </DataTableCell>

                        <DataTableCell sx={{ width: '220px' }}>
                            <Typography fontSize={largeFont} pl={'10px'}>
                                {member.contact}
                            </Typography>
                        </DataTableCell>

                        <DataTableCell sx={{ alignItems: 'center', height: '80px', justifyContent: 'center', display: 'flex', userSelect: 'none' }}>{getActiveStatus(member)}</DataTableCell>

                        {isSystemAdmin && <DataTableCell sx={{ width: '20px' }}> {member.deleted && <TrashIcon sx={{ fontSize: mediumFont }} />}</DataTableCell>}

                        <DataTableCell sx={{ width: '20px' }}></DataTableCell>

                    </StyledTableRow>
                );
            });
            return mappedList;
        }
    };

    const renderList = () => {
        let list = data;
        if (sortField) {
            switch (sortOrder) {
                case "up":
                    list = sortData(sortField, "up", data);
                    break;
                case "down":
                    list = sortData(sortField, "down", data);
                    break;
            }
        }
        const result = getFilteredList(list);
        return result;
    };

    const getOpacity = (field: string, order: string) => {
        if (field === sortField && sortOrder === order) {
            return 1;
        }
        return 0.3;
    };

    const toggleColumnSearch = (name: keyof CloudMember) => {
        const newOrder = sortOrder === "up" ? "down" : "up";
        dispatch(setListOrderState({ order: newOrder, field: name }));
    };

    const getSortedColumn = (label: string, column: keyof CloudMember) => {
        return (<Grid container alignItems="center">
            <Grid>
                <ColumnLabel onClick={() => { toggleColumnSearch(column); }} >{label}</ColumnLabel>
            </Grid>
            <Grid>
                <Stack spacing={.2} pl={2} direction="column">
                    <TwoArrowsUpIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "up"), cursor: 'pointer', color: theme.palette.primary.light }} />
                    <TwoArrowsDownIcon onClick={() => { toggleColumnSearch(column); }} sx={{ fontSize: tinyFont, opacity: getOpacity(column, "down"), cursor: 'pointer', color: theme.palette.primary.light }} />
                </Stack>
            </Grid>
        </Grid>
        );
    };

    return (
        <TableContainer ref={tableRef} sx={{ height: 'calc(100% - 95px)' }}>
            <Table ref={ref} stickyHeader>
                <TableHead>
                    <TableRow key="table-header" sx={{ height: '80px' }}>
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                        <HeaderTableCell sx={{ width: '45%' }}>
                            {getSortedColumn(t('cloud-member-list-name-header'), "name")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: '45%' }}>
                            {getSortedColumn(t('cloud-member-list-contact-header'), "contact")}
                        </HeaderTableCell>
                        <HeaderTableCell sx={{ width: '100%', minWidth: '100px' }}>
                            {getSortedColumn(t('cloud-member-list-status-header'), "active")}
                        </HeaderTableCell>
                        {isSystemAdmin && <HeaderTableCell sx={{ width: '20px' }}></HeaderTableCell>}
                        <HeaderTableCell sx={{ minWidth: '20px', width: '20px' }}></HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table >
        </TableContainer >
    );
};
export default CloudMemberList;
